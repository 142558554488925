import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

interface ApiData {
  contentType?: string,
  method?: string,
  endPoint?: string,
  body?: {},
  type?: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  handleOfferFilterClose: any;
  handleApplyOfferButton: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  offerFilter: boolean,
  listedPriceRange: any,
  minlistedPriceRange: number,
  maxlistedPriceRange: number,
  offerPriceRange: any,
  minofferPriceRange: number,
  maxofferPriceRange: number,
  categoryData: any;
  subCategoryData: any;
  subCategory: string[];
  openFromCalendar: boolean;
  openToCalendar: boolean;
  fromDateFilter: string;
  toDateFilter: string;
  activeTab: string;
  status: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class OfferFilterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  offerFilterDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End 
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
        offerFilter: false,
        listedPriceRange: [0, 0],
        minlistedPriceRange: 10,
        maxlistedPriceRange: 100,
        offerPriceRange: [0, 0],
        minofferPriceRange: 40,
        maxofferPriceRange: 80,
        categoryData: [1,2,3],
        subCategoryData: [],
        subCategory: [],
        openFromCalendar: false,
        openToCalendar: false,
        fromDateFilter: "",
        toDateFilter: "",
        activeTab: "",
        status: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.offerFilterDataApiCallId){
          this.handleOfferFilterResponse(responseJson)
        }
      }
    }
  }

  async componentDidMount() {
    this.handleOfferFilterData()
  }
  
  apiCall = async (data: ApiData) => {
    const token = await getStorageData("loginToken")

    const { contentType, method, endPoint, body } = data;
    
    const header = {
      'Content-Type': contentType,
      'token': token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleOfferFilterResponse = (responseJson: any) => {
    let listedMinPriceFloor = Math.floor(responseJson.min_listed_price)
    let listedMaxPriceFloor = Math.floor(responseJson.max_listed_price)

    let offerMinPriceFloor = Math.floor(responseJson.min_offer_price)
    let offerMaxPriceFloor = Math.floor(responseJson.max_offer_price)

    this.setState({
      listedPriceRange: [listedMinPriceFloor, listedMaxPriceFloor],
      offerPriceRange: [offerMinPriceFloor, offerMaxPriceFloor],
      subCategoryData: responseJson.categories,
      minlistedPriceRange: listedMinPriceFloor,
      maxlistedPriceRange: listedMaxPriceFloor,
      minofferPriceRange: offerMinPriceFloor,
      maxofferPriceRange: offerMaxPriceFloor
    })
  }

  handleOfferFilterData = async () => {
    this.offerFilterDataApiCallId = await this.apiCall({
      method: configJSON.httpGetType,
      endPoint: `${configJSON.offerFilterApiEndPoint}`,
    });
  }

  renderValueLabel = (value: number) => {
    return `$${value}`;
  };

  handleClearAllBtn = () => { 
    this.props.handleOfferFilterClose()
  }

  handleListedPriceChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue) && newValue.length === 2) {
      this.setState({ listedPriceRange: newValue });
    }
  };

  handleOfferPriceChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue) && newValue.length === 2) {
      this.setState({ offerPriceRange: newValue });
    }
  };

  handleSubCategorySelect = (subCat: any) => {
    const { subCategory } = this.state;
    this.setState({
      subCategory: subCategory.includes(subCat)
        ? subCategory.filter((name) => name !== subCat)
        : [...subCategory, subCat],
    });
  };

  handleFromDate = (newValue: Date | null) => {
    this.setState({ fromDateFilter: moment(newValue).format("YYYY-MM-DD") });
  };

  handleToDate = (newValue: Date | null) => {
    this.setState({ toDateFilter: moment(newValue).format("YYYY-MM-DD") });
  };

  handleToCalendar=()=>{
    this.setState({openToCalendar:!this.state.openFromCalendar})
  }

  handleFromCalendar=()=>{
    this.setState({openFromCalendar:!this.state.openFromCalendar})
  }

  handleStatusSelect = (status: string) => {
    this.setState({ status });
  };

  handleApplyButton = () => {
    const { listedPriceRange, offerPriceRange, fromDateFilter, toDateFilter, subCategory, status } = this.state;
    const filterData = {
      ...(listedPriceRange && listedPriceRange.length > 0 && { listedPriceRange }),
      ...(offerPriceRange && offerPriceRange.length > 0 && { offerPriceRange }),
      ...(subCategory && subCategory.length > 0 && { subCategory }),
      ...(fromDateFilter && { fromDateFilter }),
      ...(toDateFilter && { toDateFilter }),
      ...(status && { status })
    };
    this.props.handleApplyOfferButton(filterData)
    this.props.handleOfferFilterClose()
  }
  // Customizable Area End
}