import React from "react";
// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
  TextField,
  InputAdornment
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  styled,
  StyledEngineProvider,
} from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from '@mui/icons-material/History';
import CloseIcon from "@mui/icons-material/Close";

const MyTextField = styled(TextField)(({ theme }) => ({
  width: "480px",
  boxSizing: "border-box",
  height: "44px",
  justifyContent: "center",
  padding: "10px 8px",
  gap: 8,
  borderRadius: "25px",
  borderWidth: "0.2px 0 0 0",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  margin:"25px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      width: "100%",
    },
  },
  [theme.breakpoints.down('md')]: {
    width: "300px",
  },
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  },
}));
const SearchHistoryItem = styled("div")({
  display: 'flex',
  boxSizing:"border-box",
  width: "445px",
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '56px',
  borderBottom:"1px solid rgba(226, 232, 240, 1)",
  backgroundColor: '#fff',
  borderRadius: '4px',
  padding: '0 16px',
  marginBottom: '8px',
  cursor: 'pointer',
  '&:hover': {
  },
});
const IconWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  paddingLeft:"-10px",
  backgroundColor: "rgba(237, 230, 220, 0.5)",
});
const StyledSearchIcon = styled(SearchIcon)({
  width: "17.5px",
  height: "17.5px",
});
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth="md">
            {/* Customizable Area Start */}
            <div style={{ position: "relative", width: "fit-content", margin: "auto"  }}>
            <MyTextField
              data-test-id="inputFirstNameSearchText"
              placeholder="Search for.."
              value={this.state.searchQuery}
              onChange={(e:any)=>this.handleSearchQuery(e)}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{marginLeft:"-10px"}}>
                    <IconWrapper>
                      <StyledSearchIcon />
                    </IconWrapper>
                  </InputAdornment>
                ),
              }}
            />
     {this.state.isLoggedIn && this.state.showSearchList && this.state.searchHistoryList.length>0 && (
    <Box
      onMouseDown={(e) => e.preventDefault()} // Prevent blur when clicking inside the dropdown
      style={{
        position: "absolute",
        top: "85%",
        left: "0",
        zIndex: 10,
        boxSizing: "border-box",
        padding: "0px 20px",
        color: "rgba(83, 80, 63, 1)",
        border: "1px solid rgba(83, 80, 63, 1)",
        textAlign: "center",
        backgroundColor: "white",
        borderRadius: "8px",
        margin:"0px 25px"
      }}
    >
      {this.state.searchHistoryList.map((history: any, index: any) => (
        <SearchHistoryItem
          style={{
            marginBottom:
              index === this.state.searchHistoryList.length - 1
                ? "0px"
                : "8px",
          }}
          key={index}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
            data-test-id={`searchHistoryOption${history?.id}`}
            onClick={()=>this.handleSearchClick(history?.search_term)}
          >
            <HistoryIcon />
            <span>{history?.search_term}</span>
          </div>
          <CloseIcon
          data-test-id={`deleticon${history?.id}`}
            style={{ cursor: "pointer"}}
            onClick={() => this.deleteSearch(history?.id)}
          />
        </SearchHistoryItem>
      ))}
    </Box>
  )}
      </div>
            {/* Customizable End Start */}
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
// Customizable Area End
