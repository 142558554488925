//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField,
  Chip,
  Divider,
  // Customizable Area End
  Grid,

} from "@mui/material";
// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  styled,
  StyledEngineProvider,
} from "@mui/material/styles";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import FullWidthTabComponent from "../../../components/src/LandingPageTabList";
import { BrandList } from "../../../components/src/BrandList.web";
import SearchIcon from "@mui/icons-material/Search";
import Link from '@mui/material/Link';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CharityPolicy from "../../../components/src/popups/CharityPolicyPopUp.web";
import MakeOffer from "../../../components/src/popups/MakeOfferPopUp.web";
import OfferSuccessPopUp from "../../../components/src/popups/OfferSuccessPopUp.web";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AddCartConfirmationPopUp from "../../../components/src/popups/addToCartConfirmation.web";
import Select from 'react-select'
import CategoriesWeb from "../../categoriessubcategories/src/Categories.web";
import {rightArrowIcon} from './assets';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  }
);
const colorStyles={        
  menu: (provided) => ({
      ...provided,
      border:'1px solid #53503F',
      boxShadow:'0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
      zIndex:10000,
      borderRadius:'4px',
      marginTop:'3.93px',
      overflow:'hidden',
     
    }),
    option: (provided,state) => ({
      ...provided,
        cursor: 'pointer', 
        gap:'8px',
        boxSizing: 'border-box',
        backgroundColor: state.isSelected ? '#53503F' : state.isFocused && '#E0E0E0' || 'transparent',
        paddingRight: '16px',
        paddingLeft:'16px',
        paddingBottom:'12px',
        paddingTop:'12px',
        fontWeight: 400,
        color:state.isSelected?'#FFFFFF':'#3C3E49',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        "&:hover": {
            backgroundColor: '#53503F',
        }            
    }),
}
const sizeStyles={
                       
  menu: (provided) => ({
      ...provided,
      border:'1px solid #53503F',
      boxShadow:'0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
      zIndex:10000,
      borderRadius:'4px',
      marginTop:'3.93px',
      overflow:'hidden',
     
    }),
    option: (provided,state) => ({
      ...provided,
        cursor: 'pointer', 
        gap:'8px',
        boxSizing: 'border-box',
        backgroundColor: state?.isSelected ? '#53503F' : 'unset',
        paddingRight: '16px',
        paddingLeft:'16px',
        paddingBottom:'12px',
        paddingTop:'12px',
        fontWeight: 400,
        color:state.isSelected?'#FFFFFF':'#3C3E49',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        "&:hover": {
            backgroundColor:'#53503F',
        }
                      
    }),
  
}
const MyTextField = styled(TextField)(({ theme }) => ({
  width: "480px",
  boxSizing: "border-box",
  height: "44px",
  justifyContent: "center",
  padding: "10px 8px",
  gap: 8,
  borderRadius: "25px",
  borderWidth: "0.2px 0 0 0",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  margin:"25px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      width: "100%",
    },
  },
  [theme.breakpoints.down('md')]: {
    width: "300px",
  },
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  },
}));
const FooterButton = styled("button")({
  width: "84px",
  height: "36px",
  padding: "16px 0px",
  borderRadius: "8px",
  color: "white",
  border: "none",
  backgroundColor: "#53503F",
  fontFamily: `"Montserrat", sans-serif`,
  fontSize: "12px",
  fontWeight: 400,
  cursor: "pointer",
  display:"flex",
  justifyContent:"center",
  alignItems:"center"
});
const CustomSelect = styled(Select)`
  max-width: 240px;
  border-radius: 8px;
  height: 56px;
  
  /* Control Styling */
  .react-select__control {
    min-height: 56px;
    height: 56px;
  }

  /* Value Container Styling */
  .react-select__value-container {
    height: 56px;
    padding: 0 8px;
  }

  /* Optionally hide the indicator separator */
  .react-select__indicator-separator {
    display: none;
  }
`;
const ReactSelect = styled(Select)({
  minWidth: '240px',
  borderRadius: "8px",
  outline: 'none',
  height:'56px',
  scrollbarGutter:'stable',
 
  '& .custom-select__menu-list': {
    scrollbarWidth: '5px',
    scrollbarColor: '#53503F transparent',
    direction: 'ltr',
    borderRadius: '8px',
    overflow: 'visible', // Ensure the dropdown isn't clipped
  },
  '& .custom-select__menu-list::-webkit-scrollbar': {
    scrollbarWidth: '5px',
    scrollbarColor: '#53503F transparent',
    direction: 'ltr',
  },
  '& .custom-select__menu-list::-webkit-scrollbar-thumb': {
    scrollbarColor: '#53503F transparent',
    scrollbarWidth: '5px',
    direction: 'ltr',
    borderRadius: '8px',
  },
  '& .custom-select__menu-list:hover': {
    scrollbarWidth: '5px',
    direction: 'ltr',
    scrollbarColor: '#53503F transparent',
    borderRadius:'8px'
  },

  // Specific styling for span elements within the custom options
  "& .custom-select__menu-list span": {
    display: 'inline-block',  // Ensure label spans are visible in the menu
    paddingLeft: '8px',  // Optional: Adjust spacing if needed
  },

  "& .css-1uccc91-singleValue": {
    color: "#53503F",
    fontWeight: 600,
    fontSize: '16px',
    fontFamily: "'Montserrat', sans-serif",
  },
  "& .css-1okebmr-indicatorSeparator": {
    display: 'none'
  },
  "& .css-yk16xz-control": {
    border: 'none',
    borderColor: 'none',
    height:'100%',
  },
  "& .css-yk16xz-control:hover": {
    border: 'none',
    borderColor: 'none'
  },
  "& .css-g1d714-ValueContainer": {
    height: '56px',
    border: 'none'
  },
  "& .css-1pahdxg-control": {
    borderColor: 'transparent !important',
    outline: 'none !important',
    boxShadow: 'none !important',
    border: 'none !important',
    height:'56px',
    minHeight:'56px'
  },
  "& .custom-select__control": {
    minHeight: '56px',
    height: '56px',
  },
  "& .custom-select__value-container": {
    height: '56px',
  },
  "& .css-1wa3eu0-placeholder": {
    color: '#BABABA',
    fontWeight: 400,
    opacity: 1,
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '16px',
  },
  "& .css-1hb7zxy-IndicatorsContainer": {
    width: '24px',
    display: 'flex',
    justifyContent: "center"
  },
  "& .css-tlfecz-indicatorContainer": {
    padding: '0'
  },
  "& .css-6q0nyr-Svg": {
    color: '#53503F',
    cursor:'pointer'
  },
  "& .css-26l3qy-menu": {
    fontWeight: 400,
    color: '#3C3E49',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '12px',
    zIndex: 1000
  },
  "& .css-9gakcf-option": {
    backgroundColor: '#53503F',
    fontWeight: 600,
    color: '#FFFFFF',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '12px',
  },

  // Ensure this doesn't hide spans in the option
  "& span": {
    display: 'inline-block',  // Restore display for label spans
  },

  "@media (max-width: 400px)": {
    minWidth: 'unset'
  }
});
import {productBanner,charityHandImg,cartIcon,exportIcon,certificateIcon,heartIcon,favHeartIcon} from "./assets";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const getBreadCrumbs=(category:string,handleCategoryClick:any)=>{
  return [
    <Link
    style={{ color:"rgba(83, 80, 63, 1)",fontWeight:400}}
     underline="hover" key="1" color="inherit" href="/" >
      Home
    </Link>,
    <Link
    style={{ color:"rgba(83, 80, 63, 1)",fontWeight:400,cursor:"pointer"}}
      underline="hover"
      key="2"
      color="inherit"
      dat-test-id="bredCrumbId"
      onClick={()=>handleCategoryClick(category)}
    >
      {category?.category_name}
    </Link>,
    <Typography key="3" sx={{ color:"rgba(83, 80, 63, 1)",fontWeight:600 }}>
      Details
    </Typography>,
  ];
}
const ColorOption = (props: any) => {
  return (
    <div
      {...props.innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        cursor: "pointer",
        backgroundColor: props.isFocused ? "#E0E0E0" : "transparent",
        zIndex: 9999,
        overflow: "visible",
      }}
    >
      {/* Color Box */}
      <div
        style={{
          backgroundColor: props.data.colorCode,
          width: "28px",
          height: "28px",
          borderRadius: "6px",
          marginRight: "8px",
          border: "1px solid rgba(83, 80, 63, 1)",
        }}
      />
      {/* Label */}
      <span
        style={{
          fontFamily: `"Montserrat", sans-serif`,
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "22px",
          textAlign: "left",
          color: "rgba(60, 62, 73, 1)",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          flex: 1, 
        }}
      >
        {props.data.label}
      </span>
    </div>
  );
};
function stripHTMLTags(input) {
  if (!input || typeof input !== 'string') return '';
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');
  return doc.body.textContent || doc.body.innerText || '';
}

const SingleValue = (props:any) => {
  return (
    <div style={{ display: "flex",justifyContent:"center", alignItems: "center" }}>
      <div style={{width:"35px",height:"35px",border: "1px solid rgba(83, 80, 63, 1)",borderRadius:"6px",display:"flex",justifyContent:"center",alignItems:"center"}}>

      <div
        style={{
          backgroundColor: props.data.colorCode,
          width: "28px",
          height: "28px",
          borderRadius: "6px",
          margin: "auto",
        }}
        />
        </div>
      <span style={{fontFamily: `"Montserrat", sans-serif`,
fontSize: "16px",
fontWeight: 600,
lineHeight: "22px",
textAlign: "left",
color:"rgba(83, 80, 63, 1)",
marginLeft:"15px"
}}>{props.data.label}</span>
    </div>
  );
};

const webStyleNew = {
  productMain: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row", 
    gap:"56px",
    flexWrap: "nowrap",
  },
  productImage: {
    display: "flex",
    gap: "6px",
    flexDirection: "column"
  },
  imageMainDiv: {
    cursor:"pointer",
    border: "1px solid rgba(83, 80, 63, 1)",
    padding:"3px",
    borderRadius:"4px"
  },
  bigImage: {
    borderRadius: "4px",
    background: "rgba(255, 255, 255, 1)",
    width: "110px",
    height: "116px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bigImageStyle: {
    background: "rgba(255, 255, 255, 1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    height: "90vh",
    maxHeight: "804px",
    position: "relative", 
  },
  favouriteImage: {
    position: "absolute",
    top: "20px",
    right: "20px",
    color: "white",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    display:"flex",
    gap:"12px",
    alignItems:"center"
  },
  exportIcon: {
    color:"rgba(83, 80, 63, 1)",
    width:"26px",
    height:"26px",
    cursor:"pointer"
  },
  bigImageData: {
    width: "100%",
    maxWidth: "406px",
    height: "auto",
    maxHeight: "406px",
  },
  rightSideDescription: {
    padding: '25px'
  },
  descriptionMain: {
    display: 'flex', 
    justifyContent: 'space-between'
  },
  offer: {
    fontSize: '20px',
    color: 'rgba(83, 80, 63, 1)',
    fontWeight: 400
  },
  offerId: {
    fontWeight: 600
  },
  date: {
    fontSize: '20px',
    fontWeight: 600,
    color: 'rgba(83, 80, 63, 1)'
  },
  chipStyle: {
    margin: '5px 0px',
    width: '100%',
    backgroundColor: 'rgba(239, 238, 233, 1)', 
    color: 'rgba(83, 80, 63, 1)', 
    border: '1px solid rgba(83, 80, 63, 1)'
  },
  productNameMain: {
    textAlign: 'start'
  },
  productName: {
    color: 'rgba(83, 80, 63, 1)',
    fontSize: '24px',
    fontWeight: 600
  },
  listedItem: {
    display: 'flex',
    alignItems: 'center'
  },
  listedPriceMain: {
    border: '1px solid rgba(83, 80, 63, 1)',
    backgroundColor: '#ffffff',
    padding: '5px 35px',
    borderRadius: '5px',
    margin: '10px 0px'
  },
  listedPriceLabel: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'rgba(83, 80, 63, 1)',
  },
  listedPrice: {
    fontSize: '18px',
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)'
  },
  arrowIcon: {
    padding: '0px 10px'
  },
  divider: {
    padding: '10px 0px'
  },
  firstLabel: {
    fontSize: '16px',
    color: 'rgba(83, 80, 63, 1)',
    fontWeight: 600,
    textAlign: 'start',
    lineHeight: '40px'
  },
  secondLabel: {
    fontSize: '16px',
    color: 'rgba(83, 80, 63, 1)',
    fontWeight: 400,
    textAlign: 'start'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    border: '1px solid rgba(83, 80, 63, 1)',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  serialNumber: {
    marginRight: '8px', 
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)'
  },
  productMainProductList: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px'
  },
  price: {
    marginRight: '16px',
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)'
  },
  colorText: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    padding: '0px 20px',
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)'
  },
  colorBox: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    marginRight: '8px',
    borderRadius: '50%',
  },
  dottedDivider: {
    padding: '10px 0px'
  },
  btnMain: {
    backgroundColor: 'transparent',
    color: 'rgba(83, 80, 63, 1)',
    border: '1px solid rgba(83, 80, 63, 1)',
    marginTop: '10px',
    borderRadius: '4px',
    textTransform: 'none',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#403D2F',
    },
    '.MuiButtonBase-root-MuiButton-root': {
      padding: '100px'
    }
  },
  actionBtnMain: {
    textAlign: 'start'
  }
}
// Customizable Area End


import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";
import { color } from "react-native-elements/dist/helpers";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInputField() {
    const labelArray = [
      {id: 0, firstLabel: 'Wear',secondLabel: 'Menswear'},
      {id: 1, firstLabel: 'Category',secondLabel: 'Watches'},
      {id: 2, firstLabel: 'Brand',secondLabel: 'Rolex'},
      {id: 3, firstLabel: 'Condition',secondLabel: 'Very Good'},
      {id: 4, firstLabel: 'Material',secondLabel: 'Leather'},
      {id: 5, firstLabel: 'Use',secondLabel: 'New'},
      {id: 6, firstLabel: 'Water Resistance',secondLabel: 'Yes'},
      {id: 7, firstLabel: 'Year of Production',secondLabel: '2020'},
      {id: 8, firstLabel: 'Packages',secondLabel: 'Original box'},
      {id: 7, firstLabel: 'Dust Bag',secondLabel: 'Yes'},
    ]
    return (
      <Box data-testid="offerProductDescription">
        <Grid container spacing={1}>
          <Grid container item md={4} lg={4} sm={12} className="productDetails" style={webStyleNew.productMain}>
            <Grid container item md={1} lg={1} sm={2} xs={12} className="productImages" style={webStyleNew.productImage} >
              {this.state.productDescriptionData?.catalogue?.attributes?.attachments?.map((ele: any,index:any) => (
                <div data-testid='imageTestId' style={webStyleNew.imageMainDiv} onClick={()=>this.handleImageClick(ele)}>
                  <div data-test-id="bigImgId" style={webStyleNew.bigImage}>
                    <img
                      style={{ borderRadius: "4px" }}
                      height={67}
                      width={67}
                      src={ele?.attributes?.high_media_url}
                      alt=""
                    />
                  </div>
                </div>     
              ))}
            </Grid>
            <Grid item md={10} lg={10} sm={12} xs={12} container className="bigImg" style={webStyleNew.bigImageStyle}>
              <div style={webStyleNew.favouriteImage}>
                {this.FavoriteImage()}
                <img style={webStyleNew.exportIcon} src={exportIcon} alt="exportIcon" />
              </div>
          
              <img
                style={webStyleNew.bigImageData}
                src={this.state.bigImageData?.attributes?.high_media_url}
                alt=""
              />
            </Grid>
          </Grid>
          <Grid md={8} lg={8} sm={12} style={webStyleNew.rightSideDescription}>
              <Box style={webStyleNew.descriptionMain}>
                <Typography className="montserratFont" style={webStyleNew.offer}>Offer ID: 
                  <span style={webStyleNew.offerId}>#12345</span>
                </Typography>
                <Box>
                  <Typography className="montserratFont" style={webStyleNew.date}> Aug 8, 2024 </Typography>
                  <Chip label='Accepted' sx={webStyleNew.chipStyle} />
                </Box>
              </Box>
              <Box style={webStyleNew.productNameMain}>
                <Typography className="montserratFont" style={webStyleNew.productName}> Rolex Watch </Typography>
              </Box>
              <Box style={webStyleNew.listedItem}>
                <Box style={webStyleNew.listedPriceMain}>
                  <Typography className="montserratFont" style={webStyleNew.listedPriceLabel}>Listed Price</Typography>
                  <Typography className="montserratFont" style={webStyleNew.listedPrice}>$ 129.65</Typography>
                </Box>
                <Box style={webStyleNew.arrowIcon}><img src={rightArrowIcon} alt="rightArrowIcon"/></Box>
                <Box style={webStyleNew.listedPriceMain}>
                  <Typography className="montserratFont" style={webStyleNew.listedPriceLabel}>Listed Price</Typography>
                  <Typography className="montserratFont" style={webStyleNew.listedPrice}>$ 129.65</Typography>
                </Box>
              </Box>
              <Divider style={webStyleNew.divider}/>   
              <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                {labelArray.map((label) => 
                <Box style={{ flexBasis: '17%', margin: '10px 10px' }}>
                  <Typography className="montserratFont" style={webStyleNew.firstLabel}>{label.firstLabel}</Typography>
                  <Typography className="montserratFont" style={webStyleNew.secondLabel}>{label.secondLabel}</Typography>
                </Box>
                )}           
              </Box>
              <Box>
                <Typography className="montserratFont" style={webStyleNew.firstLabel}>{configJSON.descriptionLabel}</Typography>
                <Typography className="montserratFont" style={webStyleNew.secondLabel}>{configJSON.description}</Typography>
              </Box>
              <Box>
                <Typography className="montserratFont" style={webStyleNew.firstLabel}>{configJSON.productVariant}</Typography>
                <Box sx={webStyleNew.container} data-testid="testId">
                  <Box sx={webStyleNew.leftSide}>
                  <Typography className='montserratFont' sx={webStyle.serialNumber}>1</Typography>
                    <Box sx={webStyleNew.productMainProductList}>
                      <img 
                        src={rightArrowIcon}
                        alt="Product Image" 
                        style={{
                          width: '50px',
                          height: '50px',
                          objectFit: 'cover',
                          marginRight: '8px',
                          borderRadius: '2px',
                          border: '1px solid rgba(83, 80, 63, 1)'
                        }}/>
                      <Typography className="montserratFont" sx={webStyleNew.price}>$1234</Typography>
                    </Box>
                    <Typography className="montserratFont" sx={webStyleNew.colorText}>
                      <Box sx={{ ...webStyleNew.colorBox, backgroundColor: 'purple' }} />
                      <Box>Purple</Box>
                    </Typography>
                    <Typography className="montserratFont" sx={webStyleNew.colorText}>Medium</Typography>
                  </Box>
                </Box>
              </Box>
              <Divider style={webStyleNew.dottedDivider}/>
              <Box style={webStyleNew.actionBtnMain}>
                <Button 
                  variant="contained" 
                  className="montserratFont"
                  startIcon={<SearchIcon />}
                  sx={{
                    ...webStyleNew.btnMain, 
                    fontWeight: 600,
                    marginRight: '30px',
                    '&.Mui-disabled': {
                      backgroundColor: '#53503F', 
                      color: '#EDE6DC', 
                      cursor: 'not-allowed',
                      opacity: 0.5,
                      fontSize: '18px'
                    },
                  }}
                >
                  {configJSON.withdrawOfferBtn}
                </Button>
                <Button 
                  variant="contained" 
                  className="montserratFont"
                  startIcon={<SearchIcon />}
                  sx={{
                    ...webStyleNew.btnMain, 
                    backgroundColor: 'rgba(83, 80, 63, 1)',
                    color: '#ffffff',
                    fontWeight: 600,
                    '&.Mui-disabled': {
                      backgroundColor: '#53503F', 
                      color: '#EDE6DC', 
                      cursor: 'not-allowed',
                      opacity: 0.5,
                      fontSize: '18px'
                    },
                  }}
                >
                  {configJSON.buyNowBtn}
                </Button>
              </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }

  FavoriteImage=()=>{
    return <img className="heartIcon"
    data-test-id="favoriteBtn"
  onClick={() => {
     this.handleAddToFavorite(this.state.productDescriptionData?.catalogue?.attributes?.catalogue_variant_id)
  }}
  style={{cursor:"pointer"}}
  height={24} width={24}
  src={this.state.productDescriptionData?.catalogue?.attributes?.is_favorited ? favHeartIcon : heartIcon} alt="" />
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div
        data-test-id="productContainer"
        style={{
          backgroundColor: "#EDE6DC",
          textAlign: "center",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <AppHeader
           data-test-id="headerTest"
            closeLoginModal={this.closeLoginModal}
            openLoginModal={this.state.openLoginModal}
            count={this.state.cartLength}
            allFilter={this.state.allFilter} 
            handleCloseAllFilter={this.handleCloseAllFilter}
            categoryPageVisibility={this.handleCategoryVisibility}
            handleApplyButton={this.handleApplyButton}
            categoryId={this.state.categoryId}
            genderForFilter={this.state.gender}
        />
        <FullWidthTabComponent 
            categoriesOnHoverList={this.state.categoriesOnHoverList}
            getCategoriesOnHover={this.getCategoriesOnHover}
            categoriesList={this.state.categoriesArray}
            handleCategories={this.handleCategoryData}
            handleMoveToHome={this.handleCategoryVisibility}
        />
        <div style={{ width: "100%", textAlign: "center" }}>
          <MyTextField
            placeholder="Search for.."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
     {this.state.showCategoryPage?
          <CategoriesWeb navigation={this.props.navigation} id={""} filterData={this.state.filterData} categoryData={this.state.categoryData} passDataToFilter={this.handlePassData} allFilter={this.handleAllFilter} categoryId={this.state.categoryId} categoryName={this.state.categoryName}/> :
     
     (<>  <div
          className="productDetails"
          style={{
            boxSizing: "border-box",
            padding: "5px 40px",
            width: "100%",
          }}
        >
          <div className="breadcrumb" style={{marginBottom:"20px"}}>
            <Stack spacing={1}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{
                  '& .MuiBreadcrumbs-separator': {
                    marginLeft: '-1px',
                    marginRight:"3px"
                  },
                }}
              >
                {getBreadCrumbs(this.state.productDescriptionData?.catalogue?.attributes,this.handleCategoryClick)}
              </Breadcrumbs>
            </Stack>
          </div>
          <div style={{display: 'none'}}>{this.renderInputField()}</div>
          <Grid
  container
  spacing={1}
  style={{
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
  }}
>
  <Grid
    container
    item
    md={8}
    lg={8}
    sm={12}
    // spacing={0}
    className="productDetails"
    style={{
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row", 
      gap:"56px",
      flexWrap: "nowrap",
    }}
  >
    {/* Product Images Grid */}
    <Grid
      container
      item
      md={1}
      lg={1}
      sm={2}
      xs={12} 
      className="productImages"
      style={{
        display: "flex",
        gap: "6px",
        flexDirection: "column", // Stacks images vertically on smaller screens
      }}
    >
      {this.state.productDescriptionData?.catalogue?.attributes?.attachments?.map((ele: any,index:any) => (
        <div data-test-id={`images${index}`} style={{cursor:"pointer",border: "1px solid rgba(83, 80, 63, 1)",padding:"3px",borderRadius:"4px"}} onClick={()=>this.handleImageClick(ele)}>
        <div
          data-test-id="bigImgId"
          style={{
            borderRadius: "4px",
            background: "rgba(255, 255, 255, 1)",
            width: "110px",
            height: "116px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ borderRadius: "4px" }}
            height={67}
            width={67}
            src={ele?.attributes?.high_media_url}
            alt=""
          />
        </div>
        </div>

      ))}
    </Grid>
    <Grid
  item
  md={10}
  lg={10}
  sm={12}
  xs={12}
  container
  className="bigImg"
  style={{
    background: "rgba(255, 255, 255, 1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    height: "90vh",
    maxHeight: "804px",
    position: "relative", // Add position relative to anchor the overlay
  }}
>
  {/* Overlay div in the top-right corner */}
  <div
    style={{
      position: "absolute",
      top: "20px",
      right: "20px",
      color: "white",
      padding: "4px 8px",
      borderRadius: "4px",
      fontSize: "12px",
      display:"flex",
      gap:"12px",
      alignItems:"center"
    }}
  >
    {this.FavoriteImage()}
    <img style={{color:"rgba(83, 80, 63, 1)",width:"26px",height:"26px",cursor:"pointer"}} src={exportIcon} alt="exportIcon" />
  </div>

  <img
    style={{
      width: "100%",
      maxWidth: "406px",
      height: "auto",
      maxHeight: "406px",
    }}
    src={this.state.bigImageData?.attributes?.high_media_url}
    alt=""
  />
</Grid>

  </Grid>

  <Grid
            md={4}
            lg={4}
              container
              style={{
                flex: "1",
                textAlign: "left",
                flexWrap: "nowrap",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{paddingBottom:"10px",display:"flex",flexDirection:"column",gap:"4px",boxShadow: "0 0.5px 0 rgba(83, 80, 63, 1)"}}>

              <div
                item
                md={12}
                lg={12}
                sm={12}
                textAlign={"left"}
                style={{
                  fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "26px",
                  fontWeight: 600,
                  lineHeight: "32px",
                  letterSpacing: "-0.11999999731779099px",
                  textAlign: "left",
                  color:"rgba(83, 80, 63, 1)"
                }}
              >
                {this.state.productDescriptionData?.catalogue?.attributes?.name}
              </div>
              <div
                style={{
                  ...webStyle.textColor,
                  fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "30px",
                }}
              >
               {this.state.productDescriptionData?.catalogue?.attributes?.product_heading}
              </div>
              <div
                style={{
                  ...webStyle.textColor,
                  fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "30px",
                  fontWeight: 600,
                  lineHeight: "32px",
                  letterSpacing: "-0.11999999731779099px",
                  textAlign: "left",
                }}
              >
                {this.state.productDescriptionData?.catalogue?.attributes?.price}
              </div>
              </div>
              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  marginTop: "12px",
                }}
                style={{ display: "flex", marginTop: "12px" }}
              >
                {/* Color Selection */}
                <Grid 
                  item 
                  xs={12} 
                  md={6} 
                  style={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    gap: "15px" 
                  }}
                >
                  <span
                    style={{
                      ...webStyle.textColor,
                      fontFamily: `"Montserrat", sans-serif`,
                      fontSize: "18px",
                      fontWeight: 600,
                      lineHeight: "21.94px",
                      letterSpacing: "-0.12px",
                      textAlign: "left",
                    }}
                  >
                    Select Color*
                  </span>

                  <ReactSelect
                  data-test-id="colorSelect"
                  value={this.state.selectedColor}
                  onChange={(selectedOption:any)=>this.handleChange(selectedOption,"selectedColor")}
        styles={colorStyles}
  classNamePrefix="custom-select"
  components={{ IndicatorSeparator:null, Option: ColorOption, SingleValue: SingleValue }}
  options={this.getColorOptions()}
/>

                </Grid>
           

                {/* Size Selection */}
                <Grid 
                  item 
                  xs={12} 
                  md={6} 
                  style={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    gap: "15px" 
                  }}
                >
                  <span
                    style={{
                      ...webStyle.textColor,
                      fontFamily: `"Montserrat", sans-serif`,
                      fontSize: "18px",
                      fontWeight: 600,
                      lineHeight: "21.94px",
                      letterSpacing: "-0.12px",
                      textAlign: "left",
                    }}
                  >
                    Select Size*
                  </span>
                  <ReactSelect 
                  data-test-id="sizeSelect"
                   value={this.state.selectedSize}
                   onChange={(selectedOption:any)=>this.handleChange(selectedOption,"selectedSize")}
                  styles={sizeStyles}
                    classNamePrefix="react-select" 
                    components={{ IndicatorSeparator:null }} 
                    options={this.getSizeOptions()} 
                  />
                </Grid>
              </Grid>


               
                <div style={{marginTop:"15px",display:"flex",flexDirection:"column",gap:"4px"}}>
                <span style={{
                  color: "rgba(83, 80, 63, 1)",fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "21.94px",
                  letterSpacing: "-0.11999999731779099px",
                  textAlign: "left",
                }}>Description</span>
                <p style={{
                  fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  textAlign: "left",
                  color: "rgba(83, 80, 63, 1)",
                  marginTop:"-13px"
                }}/>
                {stripHTMLTags(this.state.productDescriptionData?.catalogue?.attributes?.description)}
                </div>
                <div className="addToCartBtn" style={{display:"flex",gap:"0.75rem",marginTop:"12px"}}>
                <button style={{
                  width: "340px",
                  height: "56px",
                  borderRadius: "4px",
                  background:"rgba(83, 80, 63, 1)",
                  color:"rgba(237, 230, 220, 1)",
                  fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  gap:"14px",
                  border:"none",
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center",
                  cursor: this.state.disableAddtoCartBtn ? "not-allowed" : "pointer",
                  opacity: this.state.disableAddtoCartBtn ? 0.6 : 1,
                }}
                disabled={this.state.disableAddtoCartBtn}
                data-test-id="addtoCart"
                onClick={this.addToCart}
                > <img src={cartIcon} height={24} width={24} alt="" />  Add to Cart</button>
                <button style={{
                  width: "157px",
                  background:"rgba(237, 230, 220, 1)",
                  height: "56px",
                  borderRadius: "4px",
                  color:"rgba(83, 80, 63, 1)",
                  fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  cursor:"pointer",
                  border:"1px solid rgba(83, 80, 63, 1)"
                }} 
                data-test-id="makeOfferBtn"
               onClick={this.handleMakeOfferModal}>Make an Offer</button>
                </div>
                <ul className="point" style={{paddingLeft:"15px",marginTop:"15px"}}>
                  <li>Estimated Delivery: 2 Aug - 9 Aug</li>
                </ul>
                {this.state.offerSuccessModal && <OfferSuccessPopUp openModal={this.state.offerSuccessModal}handleClose={this.handleOfferSuccess}/>}
                {this.state.openOfferModal && <MakeOffer
                data-test-id="makeofferTest"
                 handleChange={this.makeAnOfferChange}
                 offerValue={this.state.makeOffer}
                 offerErr={this.state.offerErr}
                 handleSubmit={this.makeAnOffer}
                 productData={this.state.productDescriptionData} openModal={this.state.openOfferModal} handleMakeOfferModal={this.handleMakeOfferModal} />}
                <div className="accordion">
                <Accordion style={{background:"rgba(237, 230, 220, 1)",marginTop:"16px",boxShadow:"none",border:"1px solid rgba(83, 80, 63, 1)"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontFamily: `"Montserrat", sans-serif`,
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "24px",
            textAlign: "left",
            color:"rgba(83, 80, 63, 1)"
            }}
        >
          More Details
        </AccordionSummary>
        <AccordionDetails>
       <hr style={{width:"100%",marginTop:"-14px"}} />
       <div style={{display:"flex",flexDirection:"column",gap:"21px",marginTop:"20px"}}>

       <div>Wear : {this.state.productDescriptionData?.catalogue?.attributes?.gender!=="female"?"Menswear":"WomensWear"}</div>
       <div>Category : {this.state.productDescriptionData?.catalogue?.attributes?.category_name}</div>
       <div>Sub Category :{this.state.productDescriptionData?.catalogue?.attributes?.sub_category_name}</div>
       <div>Brand : {this.state.productDescriptionData?.catalogue?.attributes?.brand_name}</div>
       <div>Condition : {this.state.productDescriptionData?.catalogue?.attributes?.product_condition}</div>
       <div>Material : Leather</div>
       <div>Use : {this.state.productDescriptionData?.catalogue?.attributes?.product_condition}</div>
       <div>Year of Production : {this.state.productDescriptionData?.catalogue?.attributes?.product_year_production}</div>
       <div style={{fontFamily: `"Montserrat", sans-serif`,
fontSize: "16px",
fontWeight: 600,
lineHeight: "19.5px",
letterSpacing: "-0.11999999731779099px",
textAlign: "left",
color:"rgba(83, 80, 63, 1)",
display:"flex",
justifyContent:"space-between",
alignItems:"center"
}}><span>Certificates</span> 
  <div>
        <img
          onClick={this.downloadFile}
          style={{
            cursor: this.state.productDescriptionData.catalogue?.attributes?.certificates?.length > 0 ? "pointer" : "not-allowed",
            opacity: this.state.productDescriptionData.catalogue?.attributes?.certificates?.length > 0 ? 1 : 0.5,
          }}
          src={certificateIcon}
          alt="certificate"
        />
      </div>
</div>
       </div>

        </AccordionDetails>
      </Accordion>
                </div>
                <div className="charity" style={{display:"flex",position:"relative",alignItems:"center",marginTop:"15px"}}>
                 <div style={{background:"rgba(237, 230, 220, 1)",border:"1px solid rgba(83, 80, 63, 1)",width:"49px",height:"49px",borderRadius:"50%",position:"relative",zIndex:99,display:"flex",justifyContent:"center",alignItems:"center"}}>
                 <img
                  src={charityHandImg}
                  width={28}
                  height={22}
                  />
                 </div>
                 
                    <div style={{boxSizing:"border-box",marginLeft:"-32px",height:"46px",paddingLeft:"40px",display:"flex",justifyContent:"space-between",border:"1px solid rgba(83, 80, 63, 1)",width:"100%",alignItems:"center"}}>
                      <span style={{
                        fontFamily: `"Montserrat", sans-serif`,
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "21.94px",
                        textAlign: "left",
                        
                      }}>Our Commitment to Charity</span>
                      <InfoOutlinedIcon  data-test-id="charityBtn"onClick={this.handleCharityModal} style={{cursor:"pointer",marginRight:"10px",fontWeight:"normal",color:"rgba(83, 80, 63, 1)"}}/>
                    </div>
                </div>
            </Grid>
</Grid>

        </div>

        <BrandList
          index={0}
          navigateToDetailedProductView={this.navigateToDetailedProductView}
          brandsList={this.state.featuredProducts}
          categoryLable={"Featured Products"}
          shwoBrandComponent={false}
          ViewAllLink={"/"}
          onFavoriteClick={this.handleAddToFavorite}
          brandNameDesc={"Explore the latest collections from our most loved in house labels"}
        />

        <div
          style={{
            boxSizing: "border-box",
            padding: "0px 2.5rem",
            width: "100%",
          }}
        >
          <div
            style={{
              boxSizing: "border-box",
              padding: "0 40px",
              paddingBottom: "50px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${productBanner})`,
              height: "400px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              style={{
                fontFamily: "Big Caslon",
                fontSize: "45px",
                fontWeight: 500,
                lineHeight: "54px",
                letterSpacing: "0.25px",
                textAlign: "left",
                color: "white",
                marginLeft: "25px",
              }}
            >
              Luxury Goggles: Tommy Hilfiger
            </Typography>
            <Button
              sx={{
                fontFamily: `"Montserrat", sans-serif`,
                color: "rgba(237, 230, 220, 1)",
                // color: "black",
                width: "160px",
                height: "44px",
                marginLeft: "25px",
                borderRadius: "4px",
                border: "1px solid rgba(237, 230, 220, 1)",
                ":hover": {
                  background: "transparent",
                },
              }}
            >
              Shop Now
            </Button>
          </div>
        </div>
        <div
          style={{
            height: "333px",
            width: "100%",
            padding: "63px 6rem 0 6rem",
            background: "rgba(239, 238, 233, 1)",
            boxSizing: "border-box",
            display: "flex",
          }}
        >
          <div
            className="contactDiv"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "503px",
              textAlign: "left",
              height: "fit-content",
              marginRight: "92px",
              marginLeft: "50px",
            }}
          >
            <span
              style={{
                fontFamily: `"Montserrat", sans-serif`,
                fontSize: "22px",
                fontWeight: 500,
                lineHeight: "36px",
                textAlign: "left",
                ...webStyle.colorCss,
              }}
            >
              Contact Info
            </span>
            <div
              class="my-div"
              style={{ ...webStyle.assistText, ...webStyle.colorCss }}
            >
              We are always happy to <br /> assist you
            </div>
          </div>
          <div
            className="emailDiv"
            style={{
              marginRight: "210px",
              display: "flex",
              width: "246px",
              flexDirection: "column",
              textAlign: "left",
              gap: "15px",
            }}
          >
            <span style={{ ...webStyle.emailAdd, ...webStyle.colorCss }}>
              Email Address
            </span>
            <hr
              style={{
                width: "50px",
                marginLeft: "0px",
                ...webStyle.hrCss,
                ...webStyle.colorCss,
              }}
            />
            <span style={{ ...webStyle.eid, ...webStyle.colorCss }}>
              help@info.com
            </span>
            <span style={{ ...webStyle.timingsCss, ...webStyle.colorCss }}>
              Assistance hours:{" "}
            </span>
            <span style={{ ...webStyle.timingsCss, ...webStyle.colorCss }}>
              Monday - Friday 6 am to 8 pm EST
            </span>
          </div>
          <div
            className="orderDiv"
            style={{
              display: "flex",
              width: "246px",
              flexDirection: "column",
              textAlign: "left",
              gap: "15px",
            }}
          >
            <span style={{ ...webStyle.emailAdd, ...webStyle.colorCss }}>
              Order by Phone
            </span>
            <hr
              style={{
                width: "50px",
                marginLeft: "0px",
                ...webStyle.hrCss,
                ...webStyle.colorCss,
              }}
            />
            <span style={{ ...webStyle.eid, ...webStyle.colorCss }}>
              (808) 998-34256
            </span>
            <span style={{ ...webStyle.timingsCss, ...webStyle.colorCss }}>
              Assistance hours:{" "}
            </span>
            <span style={{ ...webStyle.timingsCss, ...webStyle.colorCss }}>
              Monday - Friday 6 am to 8 pm EST
            </span>
          </div>
        </div></>)}
        {this.state.addToCartConfirmModal && <AddCartConfirmationPopUp open={this.state.addToCartConfirmModal} handleClose={this.handleAddToCartModal} addtoCart={this.addToCart} />}
        {this.state.openCharityModal && <CharityPolicy data={this.state.charityPolicyData} openModal={this.state.openCharityModal} handleClose={this.handleCharityModal}/>}
        <Footer openLoginModal={this.openLoginModal}  handleCategoryData={this.handleCategoryData} categoriesList={this.state.categoriesArray} />
      </div> as ReactNode
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: `"Montserrat", sans-serif`,
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  colorCss: {
    color: "rgba(83, 80, 63, 1)",
  },
  fontMontserrat: {
    fontFamily: `"Montserrat", sans-serif`,
  },
  font500: {
    fontWeight: 500,
  },
  fontSize22: {
    fontSize: "22px",
  },
  fontSize32: {
    fontSize: "32px",
  },
  assistText: {
    fontFamily: "Big Caslon",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "41.6px",
    textAlign: "left",
  },
  emailAdd:{
    fontFamily: `"Montserrat", sans-serif`,
fontSize: "20px",
fontWeight: 600,
lineHeight: "24.38px",
textAlign: "left",

  },
  eid:{
    fontFamily: `"Montserrat", sans-serif`,
fontSize: "18px",
fontWeight: 600,
lineHeight: "21.94px",
textAlign: "left",

  },
  timingsCss:{
    fontFamily: `"Montserrat", sans-serif`,
fontSize: "16px",
fontWeight: 400,
lineHeight: "32px",
textAlign: "left",
  },
  hrCss:{
    border:"3px solid rgba(83, 80, 63, 1)",
    width:"27px"
  },
  textColor:{
    color:"rgba(83, 80, 63, 1)"}
};
// Customizable Area End
