import React from "react";

// Customizable Area Start
import BrandController,{
    Props
} from "./BrandController.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class BrandWeb extends BrandController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <p style={webStyle.textColor}>brands</p>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    textColor: {
        color: 'red'
    }
};
// Customizable Area End