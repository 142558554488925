import React from "react";
// Customizable Area Start

import UserProfileBuyerController, {
    Props,
    configJSON,
    CountryInterArr
  } from "./UserProfileBuyerController.web";
  import {
      Box,
      styled,
      Typography,
      Modal,
      TextField,
      Button,
      Divider
      } from "@mui/material";
    import { AppHeader } from "../../../components/src/AppHeader.web";
  import { Footer } from "../../../components/src/Footer.web";
  import 'react-phone-number-input/style.css'
  import PhoneInput from 'react-phone-number-input'
  import Select from "react-select";
  import {downloadIcon, leftCircle, rightCircle, penIcon,crossIcon,rightArrowIcon,uploadIcon,roundCrossIcon} from "./assets"

// Customizable Area End

export default class UserProfileShopper extends UserProfileBuyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    hanldeReturnTextBox = (lable: string, value: string | null | undefined, lable2: string, value2: string | undefined) => {

        return <FlexBoxOuter data-test-id='flexBoxOuter '>
            <Box className='leftBox'>
                <Typography className="lable">{lable}</Typography>
                <Typography className="lableValue">{value}</Typography>
            </Box>
            <Box className='rightBox'>
                <Typography className="lable">{lable2}</Typography>
                <Typography data-test-id='lableValue' className="lableValue">{value2}</Typography>
            </Box>
        </FlexBoxOuter>
    }
    handleReturnInputField = (label: string, placeholder: string, stateValue: string, state: string) => {
        return (
            <Box className='singleInputParentBox'>
                <LabelTxt >
                    {label}
                </LabelTxt>
                <InputFields
                    fullWidth
                    error={state == 'email' && this.state.emailErr}
                    placeholder={placeholder}
                    helperText={state == 'email' && this.state.emailErr ? configJSON.emailErr : ''}
                    value={stateValue.trimStart()}
                    variant="outlined"
                    onChange={(event) => this.handleChangeInput(event.target.value, state)}
                    data-test-id='inputField'
                />

            </Box>
        )
    }
    handleReturnSelectField = (optionArrList: CountryInterArr[], label: string, placeholder: string, stateValue: CountryInterArr | {} | undefined | null, state: string) => {
        return (
            <Box className='singleInputParentBox'>
                <LabelTxt >
                    {label}
                </LabelTxt>
                <ReactSelectField
                    options={optionArrList}
                    data-testid="countryCode"
                    placeholder={placeholder}
                    value={stateValue}
                    data-test-id='selectField'
                    onChange={(event) => this.handleChangeSelect(event, state)}
                    classNamePrefix={'custom-select'}
                    isDisabled={optionArrList.length == 0}
                    styles={{

                        menu: (provided) => ({
                            ...provided,
                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
                            border: '1px solid #53503F',
                            borderRadius: '4px',
                            zIndex: 10000,
                            overflow: 'hidden',
                            marginTop: '3.93px',

                        }),
                        option: (provided, state) => ({
                            ...provided,
                            gap: '8px',
                            cursor: 'pointer',
                            boxSizing: 'border-box',
                            paddingRight: '16px',
                            backgroundColor: state?.isSelected ? '#53503F' : 'unset',
                            paddingTop: '12px',
                            paddingLeft: '16px',
                            paddingBottom: '12px',
                            color: state.isSelected ? '#FFFFFF' : '#3C3E49',
                            fontWeight: state.isSelected ? 600 : 400,
                            fontSize: '12px',
                            fontFamily: "'Montserrat', sans-serif",
                            "&:hover": {
                                backgroundColor: state?.isSelected ? '#53503F' : '#E0E0E0',
                            }

                        }),

                    }}
                />
            </Box>
        )
    }
    handleEditProfileModal = () => {
        return (
            <Box >
                <Modal
                    open={this.state.editModalOpen}
                    onClose={() => this.setState({ editModalOpen: false })}
                    aria-describedby="modal-modal-description"
                    aria-labelledby="modal-modal-title"
                    sx={{ backdropFilter: "blur(5px)" }}
                    data-test-id='editModalOpen'
                >
                    <EditProfileShopperModalBox >
                        <Box className='flexInnerBox'>
                        <Box data-test-id='crossIconBox' className="crossIconBox" onClick={() => this.setState({ editModalOpen: false })}>
                            <img src={crossIcon} alt="" />
                        </Box>
                        <Typography className="editProfileTex">{configJSON.edit_Profile}</Typography>
                        <Box className='gridInputBox'>
                            <Box className='gridInputBoxChiled'>
                                <Box className='TwoInputBox'>
                                    {this.handleReturnInputField(configJSON.first_Name, configJSON.Your_first_name, this.state.first_Name, `first_Name`)}
                                    {this.handleReturnInputField(configJSON.last_Name, configJSON.Your_last_name, this.state.last_Name, `last_Name`)}
                                </Box>
                                <Box className='TwoInputBox'>
                                {this.handleReturnSelectField(this.state.countryListArray, configJSON.location, configJSON.Select, this.state.country_id, 'country_id')}
                                    <Box className='singleInputParentBox'>
                                        <LabelTxt >
                                            {configJSON.phone_Number}
                                        </LabelTxt>
                                        <PhoneNumField
                                            sx={{ border: this.state.new_phone_numberErr ? '1px solid #DC2626' : '' }}
                                            data-test-id="txtInputPhonenumber"
                                            className="custominput"
                                            value={this.state.phoneNumber}
                                            defaultCountry={this.state.country_id?.countryCode as "AE"}
                                            countries={[]}
                                            international
                                            onChange={(event) => this.handleChangePhoneNumber(event)}
                                            countrySelectProps={{
                                                tabIndex: -1,
                                                disabled: true,
                                            }}
                                        />
                                        {this.state.new_phone_numberErr && <Typography data-test-id='phoneNumberErr' sx={styles.errorText}>{this.state.new_phone_numberErrText!==''?this.state.new_phone_numberErrText:configJSON.phoneErr}</Typography>}
                                          </Box>
                                </Box>
                                <Box className='TwoInputBox'>
                                {this.handleReturnInputField(configJSON.email, configJSON.your_Email, this.state.email, `email`)}
                                {this.handleReturnInputField(configJSON.commercial_registration_number, configJSON.enter_here, this.state.commercial_registration_number, `commercial_registration_number`)}
                                </Box>

                            </Box>
                            <UploadBox>
                                <Typography className="upload_commercial_registration_permitText">{configJSON.upload_commercial_registration_permit}</Typography>
                                <Box className='dashedBox' 
                                onDragOver={(event)=>event?.preventDefault()}
                                onDrop={this.handleDrop}
                                data-test-id='dragBox'
                                    >
                                    <Box className='dashedBoxInnerBox'>
                                        <img className="uploadImage" src={uploadIcon} alt="" />
                                        <Typography className="dashedBoxText">{configJSON.drag_and_Drop_here}</Typography>
                                        <Divider className="divider dashedBoxText">
                                            {configJSON.orText}
                                        </Divider>
                                        <Button 
                                        className="browseButton"
                                        component="label"
                                        endIcon={<img src={rightArrowIcon} />}>
                                        {configJSON.browse_files}
                                        <input data-test-id='browseFile' multiple onChange={this.handleBrowse} type="file" id='browseFile' hidden accept=".jpg,.png,.pdf" />
                                        </Button>
                                        <Typography className="dashedBoxText2">{configJSON.fileSizeText}</Typography>
                                        <Typography className="dashedBoxText2">{configJSON.fileSizeText2}</Typography>
                                    </Box>
                                </Box>
                                <Typography style={{visibility:this.state.commercialCertificateErr?'visible':'hidden'}} className="upload_commercial_registration_permitTextErr">{this.state.commercialCertificateErrText}</Typography>

                            </UploadBox>
                        </Box>
                        {this.state.previewImagesArray.length>0 &&<UploadedDocumentListBox>
                           {this.state.previewImagesArray.map((element,index)=>(
                               <Box className='imageDashedBox'>
                            <img className="dropedImage" src={element?.url} alt="" />
                            <img data-test-id='roundCrossIcon' className="roundCrossIcon" src={roundCrossIcon} alt="" onClick={()=>this.handleCrossIconRemove(index,element.blob_key,element.id)}/>
                            </Box>
                           ))}
                        </UploadedDocumentListBox>}
                        <SaveShopperButton data-test-id='saveButton' disabled={this.handleDisable()} variant="contained" onClick={()=>this.handleShopperProfileSave()}>{configJSON.save}</SaveShopperButton>
                        </Box>
                    </EditProfileShopperModalBox>
                </Modal>
            </Box>
        )
    }
    handleSuccessModal = (isOpen: boolean, state: string, heading: string, subHeading: string,subHeading2: string|null,buttonText:string) => {
        return (
            <Box >
                <Modal
                    open={isOpen}
                    onClose={() => this.setState((prevState) => ({ ...prevState, [state]: false, cartId: null,successMessage:'',isAlreadyAdded:false }))}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-test-id={state}
                    sx={{backdropFilter: "blur(5px)"}}
                >
                    <ResponseModalBox className={subHeading2!==null?'responseModalBox1':'responseModalBox'}>
                        <Box data-test-id='crossIconBox2' className="crossIconBox" onClick={() => this.setState((prevState) => ({ ...prevState, [state]: false, cartId: null,successMessage:'' ,isAlreadyAdded:false}))}>
                            <img src={crossIcon} alt="" />
                        </Box>
                        {subHeading2 !== null ? <><Typography className="modalHeading1">{heading}</Typography>
                            <Typography className='contentText1'>{subHeading}</Typography>
                            <Typography className='contentText2'>{subHeading2}</Typography>
                            <ButtonParentBox1>
                                <MainButton data-test-id='mainButton1' variant="contained" onClick={()=>this.handleOpenDownloadFolder()}>{buttonText}</MainButton>
                            </ButtonParentBox1></> :
                            <><Typography className="modalHeading">{heading}</Typography>
                                <Typography className='contentText'>{subHeading}</Typography>
                                <ButtonParentBox>
                                    <MainButton data-test-id='mainButton' variant="contained" onClick={()=>this.setState({shopperProfileUpdatedModal:false})}>{buttonText}</MainButton>
                                </ButtonParentBox></>
                        }

                    </ResponseModalBox>
                </Modal>
            </Box>
        )
    }


  // Customizable Area End

  render() {
    // Customizable Area Start
    const profileData=this.state.profileData?.attributes
    return (
        <Container>
        <AppHeader />
        <OuterBoxFirst>
            <MainBoxFirst>
                <TopBoxContainer>
                    <Box className='profileHeadingBox'>
                        <Typography className="profile">{configJSON.profile}</Typography>
                        <Typography data-test-id='edit_ProfileText' className="edit_ProfileText" onClick={() => this.handleOpenModal()}><img src={penIcon} alt="" className="pendIcon" />{configJSON.edit_Profile}</Typography>
                    </Box>
                    <img className="leftCircle" src={leftCircle} alt="" />
                    <img className="rightCircle" src={rightCircle} alt="" />
                </TopBoxContainer>
                <FormBoxMain>
                    <ProfileDetailsBoxContainer>
                        <Box className='nameEmailCountryTextBox'>
                            <Typography className="nameText">{profileData.first_name} {profileData.last_name}</Typography>
                            <Typography className="emailText">{profileData.email}</Typography>
                        </Box>
                        <PersonalInformationBoxMain>
                            <Typography className="personal_Information">{configJSON.personal_Information}</Typography>
                            {this.hanldeReturnTextBox(configJSON.first_Name, profileData.first_name, configJSON.last_Name, profileData.last_name)}
                            {this.hanldeReturnTextBox(configJSON.email, profileData.email, profileData.full_phone_number !== '' && profileData.full_phone_number !== null?configJSON.phone_Number:'', profileData.full_phone_number !== '' && profileData.full_phone_number !== null ?`+${profileData.full_phone_number}`:'')}
                            {this.hanldeReturnTextBox(configJSON.location, profileData.country_name, configJSON.commercial_registration_number, profileData.commercial_number)}
                            {this.hanldeReturnTextBox(configJSON.address,this.handleReturnText(profileData.address ,profileData.postal_code, profileData.city), '','')}
                        </PersonalInformationBoxMain>
                        <hr style={{width:"100%"}} />
                        <Box className='view_permit_documentBox'>
                            <Typography className="view_permit_documentText">{configJSON.view_permit_document}</Typography>
                            <img data-test-id='downloadIcon' className="downloadIcon" onClick={()=>this.downloadZipFile()} src={downloadIcon} alt="" />
                        </Box>
                    </ProfileDetailsBoxContainer>
                </FormBoxMain>
            </MainBoxFirst>
        </OuterBoxFirst>
        {this.handleEditProfileModal()}
        {this.state.shopperProfileUpdatedModal && this.handleSuccessModal(this.state.shopperProfileUpdatedModal, 'shopperProfileUpdatedModal', configJSON.success, configJSON.your_Profile_details_have_been_updated_successfully,null,configJSON.go_to_Profile)}
        {this.state.permitDocumentDownloadModal && this.handleSuccessModal(this.state.permitDocumentDownloadModal, 'permitDocumentDownloadModal', configJSON.permit_Document_Downloaded,configJSON.your_Permit_Document_has_been_successfully_downloaded ,configJSON.check_your_downloads_folder_to_access_it,configJSON.open_Downloads_Folder)}
        
        <Footer />
    </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Container = styled(Box)({
    backgroundColor: '#EDE6DC',
})
const OuterBoxFirst = styled(Box)({
    display: 'flex',
    padding: '50.6px 112px 50.6px 112px',
    boxSizing: 'border-box',
    justifyContent: 'center',
    "@media (max-width: 1050px)": { padding: '50.6px 50.6px 50.6px 50.6px', },
    "@media (max-width: 890px)": { padding: '50.6px 0px 50.6px 0px', }
})
const MainBoxFirst = styled(Box)({
    display: 'flex',
    width: '1196px',
    alignItems: 'center',
    flexDirection: 'column',
    "@media (max-width: 1050px)": { width: '100%', }
})
const TopBoxContainer = styled(Box)({
    height: '323px',
    backgroundColor: '#53503F',
    position: 'relative',
    width: '100%',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    "& .leftCircle": {
        left: 0,
        position: 'absolute',
        bottom: 0
    },
    "& .rightCircle": {
        right: 0,
        position: 'absolute',
        top: 0
    },
    "& .profileHeadingBox": {
        alignSelf: 'flex-end',
        width: "100%",
    },
    "& .profile": {
        margin: 'auto',
        marginTop: '58.56px',
        width: 'fit-content',
        fontSize: '32px',
        fontWeight: 600,
        color: '#EDE6DC',
        fontFamily: "'Montserrat', sans-serif",
        lineHeight: 'normal',
    },
    "& .edit_ProfileText": {
        top: '62.56px',
        position: 'absolute',
        fontWeight: 600,
        right: '50.5px',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '22px',
        display: 'flex',
        color: '#EDE6DC',
        gap: '10px',
        alignItems: 'center',
        zIndex: 1,
        cursor: 'pointer',
        "& .pendIcon": {
            width: '19.83px',
            height: '19.83px',
        }
    },
    "@media (max-width: 760px)": {
        "& .edit_ProfileText": { right: '35px', }
    },
    "@media (max-width: 560px)": {
        "& .edit_ProfileText": {
            width: 'fit-content',
            position: 'unset',
            margin: 'auto'
        }
    },
})
const FormBoxMain = styled(Box)({
    backgroundColor: '#FFFFFF',
    bottom: '76px',
    width: '678.78px',
    position: 'relative',
    left: '0px',
    display: 'flex',
    border: '1px solid #53503F',
    flexDirection: 'column',
    borderRadius: '4px',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
    alignItems: 'center',
    "@media (max-width: 1050px)": { width: '80%', },
    "@media (max-width: 760px)": { width: '90%', },
    "@media (max-width: 560px)": {
        width: '100%',
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '25px',
        bottom: '120px',
    }
})
const ProfileDetailsBoxContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    padding: '0px 90.39px 102.84px 90.39px',
    boxSizing: 'border-box',
    height: '100%',
    "& .nameEmailCountryTextBox": {
        paddingTop: "60.1px",
        paddingBottom: "25px",
        borderBottom: '1px dashed #53503F',
        width: '100%',
        boxSizing: 'border-box',
    },
    "& .nameText": {
        color: '#53503F',
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        lineHeight: 'normal',
        fontSize: '22px',
    },
    "& .emailText, .countryText": {
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        fontWeight: 400,
        lineHeight: 'normal',
        fontSize: '16px',
    },
    "& .view_permit_documentBox": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: '25px'
    },
    "& .downloadIcon":{
        cursor:'pointer'
    },
    "& .view_permit_documentText": {
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        width: 'fit-content',
        lineHeight: 'normal',
    },
    "@media (max-width: 776px)": { padding: '0px 30.39px 102.84px 30.39px', }
})
const PersonalInformationBoxMain = styled(Box)({
    display: 'flex',
    width: '115%',
    gap: '18px',
    flexDirection: 'column',
    boxSizing: 'border-box',
    paddingTop: '25px',
    marginLeft:"62px",
    paddingBottom: '18px',
    "& .personal_Information": {
        color: '#53503F',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: 'normal',
        fontFamily: "'Montserrat', sans-serif",
    }
})
const FlexBoxOuter = styled(Box)({
    display: 'flex',
    width: '100%',
    gap: '104px',

    "& .leftBox": {
        textAlign: 'left',
        width: '320px',
    },
    "& .lable": {
        fontSize: '16px',
        fontWeight: 400,
        color: '#53503F',
        fontFamily: "'Montserrat', sans-serif",
        width: 'fit-content',
        lineHeight: 'normal',
    },
    "& .lableValue": {
        fontSize: '16px',
        fontWeight: 600,
        color: '#53503F',
        fontFamily: "'Montserrat', sans-serif",
        lineHeight: 'normal',
        wordWrap: 'anyWhere',
        width: 'fit-content',
    },
    "& .rightBox": {
        textAlign: 'left',
        width: '250px',
    },
    "@media (max-width: 850px)": {
        "& .rightBox , .leftBox": { width: '200px', },
    },
    "@media (max-width: 550px)": {
        "& .rightBox , .leftBox": { width: '220px', },
    },
    "@media (max-width: 400px)": {
        flexDirection: 'column',
        gap: '18px',
        "& .rightBox , .leftBox": { width: '100%', },
    }
})
const EditProfileShopperModalBox = styled(Box)({
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: '766px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: '50px 37.5px',
    transform: 'translate(-50%, -50%) scale(0.99)',
    backgroundColor: '#EFEEE9',
    maxHeight: '100%',
    overflowY: 'auto',
    "& .flexInnerBox": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    "& .editProfileTex": {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: 'normal',
        color: '#53503F',
    },
    "& .TwoInputBox": {
        display: 'flex',
        alignItems: 'center',
        gap: '25px',
    },
    "& .TwoInputBoxButton": {
        display: 'flex',
        alignItems: 'center',
        gap: '17px',
    },
    '& .gridInputBox': {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        flexDirection: 'column',
        margin: '50px 0px'
    },
    '& .gridInputBoxChiled': {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    "& .crossIconBox": {
        alignSelf: 'end',
        position: 'absolute',
        cursor: 'pointer',
        top: '24.5px',
        right: '24px',
    },
    "& .makeAddressDefaultCheckBoxParent": {
        display: 'flex',
        gap: '10px',
        fontFamily: "'Montserrat', sans-serif",
        alignItems: 'center',
        justifyContent: 'center',
        color: '#53503F',
        fontSize: '14px',
        fontWeight: 400,
        "& img": {
            height: '24px',
            cursor: 'pointer',
            width: '24px'
        }
    },
    "& .cicularImageBox": {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    "@media (min-width:500px) and (max-width:780px)": {
        '& .gridInputBox': { width: '80%' },
        '& .gridInputBoxChiled': { width: '100%' }
    },
    "@media  (max-width:500px)": {
        '& .gridInputBox': { width: '100%' },
        '& .gridInputBoxChiled': { width: '100%' }
    },
    "@media (max-width:780px)": {
        padding: '20px 20px',
        transform: 'translate(-50%, -50%) scale(0.77)',
        maxHeight: 'unset',
        width: '95%',
        height: "unset",
        "& .TwoInputBox": {
            gap: '20px',
            flexDirection: 'column',
        },
        "& .TwoInputBox1": { alignItems: 'start' },
        '& .gridInputBox': {
            margin: '20px 0px',
            gap: '20px',
        },
        "& .singleInputParentBox": { width: '100%' }
    },
    "@media (max-width:370px)": {
        width: '125%',
        "& .singleInputParentBox": {
        }
    }
})
const UploadedDocumentListBox = styled(Box)({
    width: '100%',
    height: '100px',
    marginBottom: '50px',
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    overflowX: 'auto',
    "& .imageDashedBox": {
        height: "100%",
        border: '1px dashed #000000',
        boxSizing: 'border-box',
        position: 'relative',
    },
    "& .dropedImage": {
        height: "100%",
        width: "100px",
        boxSizing: 'border-box'
    },
    "& .roundCrossIcon": {
        position: 'absolute',
        top: '-2px',
        right: '-7px',
        cursor: 'pointer'
    },
    "@media (min-width:500px) and (max-width:780px)": {
        width: '80%'
    },
    "@media  (max-width:500px)": {
        width: '100%'
    },
})
const SaveShopperButton = styled(Button)({
    height: '56px',
    width: '360px',
    backgroundColor: '#53503F',
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'none',
    borderRadius: '4px',
    fontWeight: 600,
    color: '#EDE6DC',
    fontSize: '18px',
    cursor: 'pointer',
    '&.Mui-disabled': {
        opacity: 0.5,
        backgroundColor: '#53503F',
        cursor: 'not-allowed',
        color: '#EDE6DC',
    },
    "&:hover": {
        backgroundColor: '#53503F',
    },
    "@media (max-width:780px)": {
        width: '80%'
    },
    "@media  (max-width:500px)": {
        width: '100%'
    }
})
const InputFields = styled(TextField)({
    height: '56px',
    minWidth: "327px",
    '& input::placeholder': {
        opacity: 1,
        color: '#BABABA',
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 400,
    },
    "& .MuiInputBase-root": {
        fontWeight: 600,
        color: "#53503F",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        height: '100%'
    },
    "& .MuiOutlinedInput-notchedOutline": { borderWidth: "0px", },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
        },
        "&.Mui-focused fieldset": {
            borderWidth: "0px",
        },
    },

    "& .MuiOutlinedInput-input": {
        backgroundColor: '#FFFFFF',
        padding: "17px 8px",
        boxSizing: 'border-box',
        borderRadius: '4px',
        height: '100%'
    },

    "& .MuiFormHelperText-contained": {
        marginRight: "0px",
        marginLeft: "0px",
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: 'normal',
        color: "#DC2626",
        fontFamily: "'Montserrat', sans-serif",
    },
    '& .css-1wc848c-MuiFormHelperText-root.Mui-error': {
        margin: '0px',
        color: "#DC2626",
        marginTop: '0.5px'
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": { border: '1px solid #DC2626' },
    '@media (max-width:660px )': {
        maxWidth: "unset",
        minWidth: "unset",

    },
});
const LabelTxt = styled(Typography)({
    fontWeight: 600,
    fontFamily: "'Montserrat', sans-serif",
    color: '#53503F',
    marginBottom: '4px',
    fontSize: '16px',
})
const PhoneNumField = styled(PhoneInput)({
    boxSizing: "border-box",
    minWidth: "327px",
    zIndex: 1,
    height: '56px',
    position: "relative",
    borderRadius: 4,
    display: 'flex',
    color: "#53503F",
    paddingLeft: "8px",
    alignItems: 'center',
    paddingRight: '1px',
    backgroundColor: '#FFFFFF',
    '& input': {
        fontWeight: 600,
        border: 'none',
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
        color: "#53503F",
    },
    '& input:focus': {
        outline: 'none',
        border: 'none',
    },
    '& .PhoneInputInput': {
        fontFamily: "'Montserrat', sans-serif",
        color: "#53503F",
        fontWeight: 600,
        fontSize: '16px',
    },
    '& input:focus-visible': {
        outline: 'none',
        border: 'none',
    },
    "& .PhoneInputCountry": {
        cursor: 'pointer',
        marginRight: '8px',
    },
    '& .PhoneInputCountrySelectArrow': {
        borderLeft: '0px',
        color: '#53503F',
        borderTop: '0px',
        opacity: 2,
        borderWidth: '2.8px',
        borderRadius: '.5px',
        fontSize: '22px',
        cursor: 'pointer',
        marginLeft: '6.41px',
        display: 'none'
    },
    '@media (max-width:600px)': { minWidth: "unset" },
});
const ReactSelectField = styled(Select)({
    outline: 'none',
    minWidth: '327px',
    scrollbarGutter: 'stable',
    height: '56px',
    '& .custom-select__menu-list': {
        direction: 'ltr',
        scrollbarWidth: '5px', scrollbarColor: '#53503F transparent',
        borderRadius: '8px'
    },
    '& .custom-select__menu-list::-webkit-scrollbar': {
        scrollbarColor: '#53503F transparent',
        scrollbarWidth: '5px',
        direction: 'ltr',
    },
    '& .custom-select__menu-list::-webkit-scrollbar-thumb': {
        scrollbarWidth: '5px',
        scrollbarColor: '#53503F transparent',
        borderRadius: '8px',
        direction: 'ltr',
    },
    '& .custom-select__menu-list:hover': {
        direction: 'ltr',
        scrollbarWidth: '5px',
        borderRadius: '8px',
        scrollbarColor: '#53503F transparent',
    },
    "& .css-1uccc91-singleValue": {
        fontWeight: 600,
        color: "#53503F",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
    },
    "& .css-1okebmr-indicatorSeparator": { display: 'none' },
    "& .css-yk16xz-control": {
        borderColor: 'none',
        border: 'none',
        height: '100%',
    },
    "& .css-yk16xz-control:hover": {
        borderColor: 'none',
        border: 'none',
    },
    "& .css-g1d714-ValueContainer": {
        border: 'none',
        height: '56px',
    },
    "& .css-1pahdxg-control": {
        outline: 'none !important',
        borderColor: 'transparent !important',
        border: 'none !important',
        boxShadow: 'none !important',
        minHeight: '56px',
        height: '56px',
    },
    "& .custom-select__control": {
        height: '56px',
        minHeight: '56px',
    },
    "& .custom-select__value-container": { height: '56px', },
    "& .css-1wa3eu0-placeholder": {
        color: '#BABABA',
        opacity: 1,
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",

    },
    "& .css-1hb7zxy-IndicatorsContainer": {
        display: 'flex',
        width: '24px',
        justifyContent: "center"
    },
    "& .css-tlfecz-indicatorContainer": { padding: '0' },
    "& .css-6q0nyr-Svg": {
        cursor: 'pointer',
        color: '#53503F',
    },
    "& .css-26l3qy-menu": {
        fontWeight: 400,
        fontFamily: "'Montserrat', sans-serif",
        color: '#3C3E49',
        zIndex: 1000,
        fontSize: '12px',
    },
    "& .css-9gakcf-option": {
        fontWeight: 600,
        backgroundColor: '#53503F',
        fontFamily: "'Montserrat', sans-serif",
        color: '#FFFFFF',
        fontSize: '12px',
    },
    "& span": { display: 'none' },
    "& svg": {
        position: 'absolute',
        cursor: 'pointer',
        top: '20px',
        right: '8px',
        color: '#53503F'
    },
    "@media (max-width: 400px)": { minWidth: 'unset' }
})
const UploadBox = styled(Box)({
    width: '100%',
    "& .upload_commercial_registration_permitText": {
        fontWeight: 600,
        color: '#53503F',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        marginBottom:'6.3px'
    },
    "& .upload_commercial_registration_permitTextErr":{
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        color: 'rgb(220, 38, 38)',
        fontSize: '12px',
        marginTop:'6.3px',
        height:'19px',
    },
    "& .dashedBox":{
        border:'2px dashed #53503F',
        borderRadius:'4px',
        height:'219px',
        backgroundColor:'#53503F1A',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    "& .dashedBoxInnerBox":{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection:'column',
        gap:'8px'
    },
    "& .divider":{
        width: '155px',
        borderColor: 'gray',
    },
    "& .dashedBoxText":{
        fontWeight: 400,
        color: '#53503F',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '14px',
    },
    "& .dashedBoxText2":{
        color: '#53503F',
        fontWeight: 400,
        fontSize: '12px',
        fontFamily: "'Montserrat', sans-serif",
        textAlign:'center'
    },
    "& .uploadImage":{
        width:'21.33px',
        height:'20.77px'
    },
    "& .browseButton":{
        height: '37px',
        width: '143px',
        fontFamily: "'Montserrat', sans-serif",
        backgroundColor: '#53503F',
        borderRadius: '4px',
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '14px',
        color: '#FFFFFF',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#53503F',
        },
    }
})
const ResponseModalBox = styled(Box)({
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: '600px',
    transform: 'translate(-50%, -50%) scale(0.89)',
    padding: '68px 49px',
    borderRadius: '4px',
    display: 'flex',
    boxSizing: 'border-box',
    backgroundColor: '#EFEEE9',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    "& .responseModalBox1": {
        height: '400px',
        maxHeight: '400px',

    },
    "& .responseModalBox": {
        height: '284px',
        maxHeight: '284px',

    },
    "& .contentText": {
        fontSize: '20px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 400,
        color: '#53503F',
        marginTop: '8px',
        maxWidth: '392px',
        textAlign: "center"
    },
    "& .contentText1": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '20px',
        fontWeight: 400,
        marginTop: '8px',
        color: '#53503F',
        textAlign: "center",
        maxWidth: '462px',
    },
    "& .contentText2": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '20px',
        color: '#53503F',
        fontWeight: 400,
        textAlign: "center",
        maxWidth: '462px',
    },
    "& .crossIconBox": {
        position: 'absolute',
        right: '28px',
        top: '28px',
        cursor: 'pointer'
    },
    "& .modalHeading": {
        fontSize: '22px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
    },
    "& .modalHeading1": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '22px',
        color: '#53503F',
        fontWeight: 600,
        paddingBottom: '24px'
    },
    "@media  (max-width:550px)": {
        width: '100%',

    },
    '@media (max-width:400px )': {
        padding: '16.21px 25px 39.85px 25px',
        height: '215.57px',
        borderRadius: '4px',
        "& .modalHeading": {
            fontSize: '18px',
        },
        "& .contentText": {
            fontSize: '14px',
        },
        "& .crossIconBox": {
            right: '6px',
            top: '6px',
            "& img": {
                height: '24px',
                width: '24px'
            }
        }
    },
    '@media (max-width:362px )': {
        maxHeight: 'unset',
        height: 'unset',
    },
    '@media (max-width:359px )': {
        width: '100%',
    },
})
const MainButton = styled(Button)({
    height: '56px',
    width: '360px',
    backgroundColor: '#53503F',
    textTransform: 'none',
    borderRadius: '8px',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '18px',
    cursor: 'pointer',
    fontWeight: 600,
    color: '#EDE6DC',
    "&:hover": { backgroundColor: '#53503F', },
    '@media (max-width:400px )': {
        height: '36px',
        width: '117px',
        borderRadius: '4px',
        fontSize: '12px',
    },
})
const ButtonParentBox1 = styled(Box)({
    marginTop: '48px',
    alignItems: 'center',
    gap: '50px',
    display: 'flex',
    '@media (max-width:420px )': { gap: '28.45px', },
})
const ButtonParentBox = styled(Box)({
    alignItems: 'center',
    display: 'flex',
    marginTop: '40px',
    gap: '50px',
    '@media (max-width:420px )': { gap: '28.45px', },
})
const styles = {
    errorText: {
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        color: "#DC2626",
        position: 'absolute' as 'absolute',
        lineHeight: 'normal',
        marginTop: '2px'
    },
}
// Customizable Area End