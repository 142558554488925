Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.productNameLabel = "Product Name";
exports.productPlaceholder = "Enter Name";
exports.selectPlaceholder = "Select";
exports.categoryLabel = "Category";
exports.subCategoryLabel = "Sub Category";
exports.conditionLabel = "Condition";
exports.materialLabel = "Material";
exports.useLabel = "Use";
exports.waterResistance = "Water Resistance";
exports.description = "Description";
exports.descriptionPlaceholder = "write here";
exports.modalNumber = "Modal Number";
exports.yearOfProduction = "Year of Production";
exports.packages = "Packages"
exports.packagePlaceholder = "Original box";
exports.dustBag = "Dust Bag";
exports.productVariant = "Product variants";
exports.priceLabel = "Price";
exports.youEarnLabel = "You Earn";
exports.categoryEndPoint = "bx_block_categories/categories";
exports.subCategoryEndPoint = "bx_block_categories/sub_categories";
exports.brandEndPoint = "bx_block_catalogue/brands?page_no=1&per_page=500";
exports.conditionEndPoint = "bx_block_catalogue/catalogues/product_conditions_dropdown";
exports.materialEndPoint = "bx_block_catalogue/catalogues/material_dropdown";
exports.useEndPoint = "bx_block_catalogue/catalogues/use_dropdown";
exports.yearOfProductionEndPoint = "bx_block_request_management/wish_requests/production_year_list";
exports.packagesEndPoint = "bx_block_catalogue/catalogues/package_dropdown";
exports.colorEndPoint = "bx_block_catalogue/catalogues_variants_colors";
exports.sizeEndPoint = "bx_block_catalogue/catalogues_variants_sizes";
exports.youEarnEndPoint = "bx_block_catalogue/catalogues/calculate_earning"; 
exports.brandLabel = "Brand";
exports.selectColorLabel = "Select Color";
exports.discardText = "Discard";
exports.saveText = "Save";
exports.addVariantText = "Add Variant";
exports.clickToUpload = "Click to Upload or";
exports.dragAndDrop = "drag & Drop";
exports.note = "Note: Add receipt, authenticity, or invoice";
exports.addProductDetails = "Add Product details to sell out on TRÉSOR";
exports.sellAnItem = "Sell an Item";
exports.whatKindOfItem = "What kind of items are you looking to sell?";
exports.menText = "Men";
exports.womenText = "Women";
exports.certificate = "Certificate";
exports.optional = "(optional)";
exports.proof = "Proof of origin";
exports.addProductText = "Add Product";
exports.clickToUploadProduct = "Click to Upload Product Images or";
exports.apiMethodTypePost = "POST";
exports.uploadDocumentEndPoint = "bx_block_attachment/file_attachments/direct_upload";
exports.uploadVariantEndPoint = "bx_block_catalogue/upload_attachments/catalogue_variant";
exports.createNewCatalogueEndPoint = "bx_block_catalogue/catalogues";
exports.productImageEndPoint = "bx_block_catalogue/upload_attachments";
exports.sizeLabel = "Size *";
exports.modalNumberPlaceholder = "Enter here";
exports.oneSizeOptionLabel = "One Size";
// Customizable Area End