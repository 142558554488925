import React from "react";

// Customizable Area Start
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    styled,
    InputAdornment,
    TextField,
    Stack,
    Breadcrumbs,
    Box,
    Modal,
    Typography,
    Button,
    Slide, Backdrop,
    Chip,
    IconButton,
} from "@mui/material";
import CloseIcon  from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import Select from 'react-select'
import FilterListIcon from '@mui/icons-material/FilterList';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  createTheme,
} from "@mui/material/styles";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AppHeader } from "../../../components/src/AppHeader.web";
import CustomPagination from "../../../components/src/CustomPagination.web"
import SearchIcon from "@mui/icons-material/Search";
import {calenderIcon,notFoundIcon} from "./assets"
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const getBreadCrumbs=()=>{
    return [
      <Link
      style={{ color:"rgba(83, 80, 63, 1)",fontWeight:400}}
       underline="hover" key="1" color="inherit" href="/" >
        Home
      </Link>,
      <Link
      style={{ color:"rgba(83, 80, 63, 1)",fontWeight:600,cursor:"pointer"}}
        underline="hover"
        key="2"
        color="inherit"
        dat-test-id="bredCrumbId"
        href="/WishRequests"
      >
        My Wishes
      </Link>,
    ];
  }
// Customizable Area End

import WishRequestsWebController, {
  Props,
  configJSON,
} from "./WishRequestsWebController";

export default class WishRequets extends WishRequestsWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
NotFound(){
  return <div style={{color:"rgba(83, 80, 63, 1)",
  width:"40%",
  display:"flex",
  flexDirection:"column",
  gap:"15px",
  margin:"auto",
  justifyContent:"center",
  alignItems:"center",
  marginTop:"60px"
  }}>
  <img height={220} width={167} src={notFoundIcon} alt="hb" />
  <span style={{fontSize:"25px",fontWeight:600}}>No Results Found</span>
  <span style={{fontSize:"20px",fontWeight:400,marginTop:"-5px"}}>No results found. Please try again.</span>
  </div>
}
  confirmationModal=()=>{
    return (
        <Box  data-test-id="confirmationModal">
            <Modal
                open={this.state.openConfirmationModal}
                onClose={this.handleCloseConfirmationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-test-id='confirmModal'
            >
                <RemoveCartModalBox >
                    <Box data-test-id='crossIconConfirmModal' className="crossIconBox" onClick={this.handleCloseConfirmationModal}>
                       <CloseIcon/>
                    </Box>
                    <Typography style={webStyle.confirmation}>Confirmation</Typography>
                       <Typography className="areYouSureText">Are you sure you want to delete
                       the wish request?</Typography>
                    <CancelRemoveButtonBox>
                    <CancelButton data-test-id='NotNowBtn' onClick={this.handleCloseConfirmationModal} variant="contained" >Not now</CancelButton>
                    <RemoveButton data-test-id='yesBtn' onClick={this.handleConfirmationModal} variant="contained" >Yes</RemoveButton>
                    </CancelRemoveButtonBox>
                </RemoveCartModalBox>
            </Modal>
        </Box>
    )
}
categoryDiv(){
  return  <Box sx={{ marginBottom: '15px' }}>
    <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Category</Typography>
    <Box display="flex" flexWrap="wrap" gap={1}>
      {this.state.categoryList.length>0&&this.state.categoryList.map((subCat: any,index:any) => (
        <Chip
          className="montserratFont"
          data-testid="subcategoryTestId"
          key={subCat.id}
          label={subCat.name}
          data-test-id={`categoryChip${index}`}
          onClick={() => this.handleCategorySelect(subCat.id)}
          sx={{
            backgroundColor: this.state.selectedCategoryList.includes(subCat?.id) ? '#70624D' : 'rgba(255, 255, 255, 1)',
            color: this.state.selectedCategoryList.includes(subCat.id) ? '#FFFFFF' : '#333',
            borderRadius: '4px',
            fontWeight: this.state.selectedCategoryList.includes(subCat.id) ? 600 : 400,
            padding: '10px 16px',
            height: '40px',
            border: '1px solid rgba(83, 80, 63, 1)',
            fontSize: '12px'
          }}
          clickable
        />
      ))}
    </Box>
  </Box>
  }
  brandDiv(){
    return <Box sx={{ marginBottom: '15px' }}>
        <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Brand</Typography>
        <Box display="flex" flexWrap="wrap" gap={1}>

          {this.state.brandsList?.map((subCat: any,index:any) => (
            <Chip
            data-test-id={`brandChip${index}`}
            onClick={() => this.handleBrandSelect(subCat.id)}
            data-testid="subcategoryTestId"
            clickable
            label={subCat.name}
            key={subCat.id}
            className="montserratFont"
              sx={{
                backgroundColor: this.state.selectedBrandList.includes(subCat.id) ? '#70624D' : 'rgba(255, 255, 255, 1)',
                color: this.state.selectedBrandList.includes(subCat.id) ? '#FFFFFF' : '#333',
                borderRadius: '4px',
                fontWeight: this.state.selectedBrandList.includes(subCat.id) ? 600 : 400,
                padding: '10px 16px',
                height: '40px',
                border: '1px solid rgba(83, 80, 63, 1)',
                fontSize: '12px'
              }}
            />
          ))}
        </Box>
      </Box>
  }
  StatusDiv(){
    return <Box sx={{ marginBottom: '15px' }}>
    <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Status</Typography>
    <Box display="flex" flexWrap="wrap" gap={1}>

      {this.state.statusList.map((status: any,index:any) => (
        <Chip
        data-test-id={`statusChip${index}`}
        onClick={() => this.handleStatusFilterSelect(status.id)}
        data-testid="subcategoryTestId"
        clickable
        label={status.name||status.status}
        key={status.id}
        className="montserratFont"
          sx={{
            backgroundColor: this.state.selectedStatusFilterList.includes(status.id) ? '#70624D' : 'rgba(255, 255, 255, 1)',
            color: this.state.selectedStatusFilterList.includes(status.id) ? '#FFFFFF' : '#333',
            borderRadius: '4px',
            fontWeight: this.state.selectedStatusFilterList.includes(status.id) ? 600 : 400,
            padding: '10px 16px',
            height: '40px',
            border: '1px solid rgba(83, 80, 63, 1)',
            fontSize: '12px'
          }}
        />
      ))}
    </Box>
  </Box>
  }
  DateDiv(){
    return  <div className="date" style={{marginTop:"25px"}}>
    <span style={{fontSize:"14px",fontWeight:400}}>{"Request Date"}</span>
  <div  style={{display:"flex",gap:"10px",marginTop:"7px"}}>  
    <div>
<span style={{color:"rgba(83, 80, 63, 1)",fontSize:"14px",fontWeight:600}} className="montserratMediumGoogle">From</span>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ width: '100%', marginTop: '5px',marginBottom: '10px' }}>
          <DatePicker
            data-testid="dateOfBirthTestId"
            name='dateOfBirth'
            value={"22-11-2024"}
            data-test-id="fromDate"
            onChange={(newValue) => this.handleFromDate(newValue ? new Date(newValue) : null)}
            open={this.state.openFromCalendar}
            onClose={() => this.setState({ openFromCalendar: false })}
            slots={{
              textField: TextField 
            }}
            slotProps={{
              textField: {
                placeholder: "dd/mm/yyyy",
                InputProps: {
                  sx: {
                    '&::placeholder': {
                      color: '#BABABA',
                      textDecoration: 'none'
                    },
                  },
                  endAdornment: ( 
                    <InputAdornment position="end" > 
                      <IconButton  edge="end"  onClick={this.handleFromCalendar}> 
                        <img data-test-id="fromdateIcon" src={calenderIcon} alt='data'/> 
                      </IconButton> 
                    </InputAdornment> 

                  ), 
                },
                sx: {
                  backgroundColor: '#fff',
                  ".MuiPickersCalendarHeader-labelContainer": {
                    color: '#53503F', 
                    transform: 'translateX(-50%)',
                    left: '49%',
                    position: 'absolute', 
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border:'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '15px 14px',
                  },
                  borderRadius: '4px',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border:'none',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border:'none' 
                  },
                },
                fullWidth: true,
              },
              popper: {
                sx: {
                 
                  ".MuiPickersDay-root.Mui-selected": {
                    color: '#fff', 
                    backgroundColor: '#53503F', 
                  },                          
                
                  ".MuiPickersArrowSwitcher-root": {
                    width: '100%', 
                    justifyContent: 'space-between',
                    display: 'flex',
                    color: 'pink', 
                  },
                  ".MuiPickersDay-root:hover": {
                    backgroundColor: '#53503F',
                    color: 'black', 
                  },
                  ".Mui-selected:hover": {
                    backgroundColor: '#53503F',
                  },
                  ".MuiPickersCalendarHeader-root": {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 16px',
                    color: 'blue', 
                  },
                 
                  ".MuiPickersDay-root": {
                    color: '#53503F',
                  },
                  ".MuiPaper-root": { 
                    borderRadius: "10px",
                    backgroundColor: '#fff', 
                    color: '#53503F', 
                  }, 
                  ".MuiPickersDay-root.Mui-disabled": {
                    color: 'rgba(255, 255, 255, 0.3)', 
                  },
                  ".Mui-selected": {
                    backgroundColor: 'red', 
                    color: 'pink', 
                  },},},
            }}
          />
        </div>
      </LocalizationProvider>
    </div>

    <div>
      <span style={{color:"rgba(83, 80, 63, 1)",fontSize:"14px",fontWeight:600}} className="montserratMediumGoogle">To</span>
    
<LocalizationProvider dateAdapter={AdapterDayjs}>
  <div
    style={{
      width: '100%',
      marginTop: '5px',
      marginBottom: '10px',
    }}
  >

      <DatePicker
        onClose={() => this.setState({ openToCalendar: false })}
        open={this.state.openToCalendar}
        name="dateOfBirth"
        data-test-id="toDate"
        value={"22-11-2024"}
        slots={{
          textField: TextField,
        }}
        onChange={(newValue) => this.handleToDate(newValue ? new Date(newValue) : null)}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={this.handleToCalendar}
                  >
                    <img
                      data-test-id="todateIcon"
                      src={calenderIcon}
                      alt="calendar"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            },
            placeholder: "dd/mm/yyyy",
            sx: {
              backgroundColor: '#fff',
              '& .MuiInputBase-input': {
                padding: '15px 14px',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .Mui-error': {
                border: 'none',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              borderRadius: '4px',
            },
            fullWidth: true,
          },
          popper: {
            sx: {
              '.MuiPickersCalendarHeader-root': {
                color: 'blue',
                justifyContent: 'space-between',
                padding: '0 16px',
                alignItems: 'center',
                display: 'flex',
              },
              '.MuiPaper-root': {
                color: '#53503F',
                backgroundColor: '#fff',
                borderRadius: '10px',
              },
              '.MuiPickersCalendarHeader-labelContainer': {
                left: '49%',
                color: '#53503F',
                transform: 'translateX(-50%)',
                position: 'absolute',
              },
              '.MuiPickersArrowSwitcher-root': {
                color: 'pink',
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
              },
              '.Mui-selected:hover': {
                backgroundColor: '#53503F',
              },
              '.Mui-selected': {
                color: 'pink',
                backgroundColor: 'red',
              },
              '.MuiPickersDay-root.Mui-selected': {
                color: '#fff',
                backgroundColor: '#53503F',
              },
              '.MuiPickersDay-root': {
                color: '#53503F',
              },
              '.MuiPickersDay-root:hover': {
                color: 'black',
                backgroundColor: '#53503F',
              },
              '.MuiPickersDay-root.Mui-disabled': {
                color: 'rgba(255, 255, 255, 0.3)',
              },
            },
          },
        }}
      />
  </div>
</LocalizationProvider>
      </div></div>
  </div>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <div style={{
  backgroundColor:"rgb(237, 230, 220)",
          height:"100%"
        }}>
            <AppHeader/>
            <div style={{padding:"0px 40px",boxSizing:"border-box"}}>
            <div className="breadcrumb" style={{marginBottom:"20px",marginTop:"30px"}}>
            <Stack spacing={1}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{
                  '& .MuiBreadcrumbs-separator': {
                    marginLeft: '-1px',
                    marginRight:"3px"
                  },
                }}
              >
                {getBreadCrumbs()}
              </Breadcrumbs>
            </Stack>
          </div>
            <div className="filterAndSortOptions" style={{marginBottom:"24px",marginTop:"30px",display:"flex",height:"44px",justifyContent:"space-between"}}>
                  <div className="search" style={{display:"flex",gap:"20px"}}>
                    <div onClick={this.handleFilter} data-test-id="filterBtn" style={{cursor:"pointer",display:"flex",borderRadius:"4px",justifyContent:"center",width:"80px",alignItems:"center",height:"100%",background:"rgba(83, 80, 63, 1)",color:"rgba(255, 255, 255, 1)"}}>
                   <FilterListIcon style={{fontSize:"18px",marginRight:"8px"}}/>
                    Filter
                    </div>
                  <MyTextField
                  style={{margin:"0px"}}
              data-test-id="inputFirstNameSearchText"
              placeholder="Search.."
              value={this.state.searchQuery}
              onChange={(e:any)=>this.handlesearchChange(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{marginLeft:"-10px"}}>
                    <IconWrapper>
                      <StyledSearchIcon />
                    </IconWrapper>
                  </InputAdornment>
                ),
              }}
            />

                  </div>

                  <div className="sort" style={{display:"flex",gap:"24px"}}>
                      <ReactSelect
                        data-test-id="SortSelect"
                        value={this.state.sortOption}
                        onChange={(data:any) =>this.handleSortOptionChange(data)}
                        styles={{
                          singleValue: (provided: any) => ({
                            ...provided,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent:"center",
                            height: '100%',
                          }),
                          control: (provided: any) => ({
                            ...provided,
                            display: 'flex',
                            alignItems: 'center',
                            height: '44px',
                            backgroundColor: 'transparent',
                            border: '1px solid #ccc',
                            boxShadow: 'none',
                            width:"250px",
                            ':hover': {
                              borderColor: '#aaa',
                            },
                          }),
                          placeholder: (provided: any) => ({
                            ...provided,
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                          }),
                          dropdownIndicator: (provided: any) => ({
                            ...provided,
                            padding: '0 8px',
                            color:"rgba(83, 80, 63, 1)"
                          }),
                          menu:(provided:any,)=>({
                            ...provided,
                                 }),
                          option:(provided:any,state:any)=>({
                            ...provided,
                            backgroundColor: state?.isSelected ? '#53503F' : '',
                            "&:hover": {
                            backgroundColor: "none",
                              }
                          }),
                        }}
                        classNamePrefix="react-select"
                        components={{ IndicatorSeparator: null }}
                        options={[{value:"Sort by : Recent",label:"Sort By : Recent"},
                          {value:"request_date_new_to_old",label:"Requests Date: New to Old"},
                          {value:"request_date_old_to_new",label:"Requests Date: Old to New"},
                          {value:"older",label:"Sort By : Older"},
                        ]}
                      />
                  </div>
            </div>

  { this.state.tabelData.length >0 ?     <TableContainer
        component={Paper}
        sx={{
          "&.MuiTableContainer-root": {
            margin: 0,
            padding: 0,
            width: "100%",
            overflowX: "hidden",
            borderRadius: "8px",
            marginBottom:"58px"
          },
        }}
      >
         
        <Table
        data-test-id="tableContainer"
        sx={{ tableLayout: "auto",fontFamily: "'Montserrat', sans-serif",overflowX:"hidden" }}>
          <TableHead sx={{ background: "rgba(255, 255, 255, 1)" }}>
            <TableRow style={{ height: "60px"}}>
                {configJSON.tabelHeadings.map((heading:any)=>{
                    return  <TableCell style={{textAlign:"left",fontWeight:600,color:"rgba(83, 80, 63, 1)",fontSize:"14px",fontFamily: "'Montserrat', sans-serif",}}>{heading.label}</TableCell>
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.tabelData?.map((row:any,index:number) => (
              <TableRow     sx={{
                fontFamily: "'Montserrat', sans-serif",
                textAlign:"center",
                background:
                  index % 2 == 0
                  ?"rgba(255, 255, 255, 1)"
                  : "rgba(251, 250, 248, 1)"
              }} key={row.attributes?.id}>
                <TableCell style={{...webStyle.fontFamily,textAlign:"left"}}>{row.attributes?.id}</TableCell>
                <TableCell style={{...webStyle.fontFamily,alignItems:"left"}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"80px",height:"60px",background:"rgb(239, 238, 233)",borderRadius:"4px"}}>
                    <img
                    src={row.attributes?.product_image[0]?.url}
                    alt={row.attributes?.product_name}
                    style={{ width: 49, height: 35, objectFit: "cover" }}
                  />
                    </div>
                 
                </TableCell>
                <TableCell style={{...webStyle.fontFamily,textAlign:"left"}}>{row.attributes?.request_date}</TableCell>
                <TableCell style={{...webStyle.fontFamily,textAlign:"left"}}>{row.attributes?.name}</TableCell>
                <TableCell style={{...webStyle.fontFamily,textAlign:"left"}}>{row.attributes?.category}</TableCell>
                <TableCell style={{...webStyle.fontFamily,textAlign:"left"}}>{row.attributes?.product_model_number}</TableCell>
                <TableCell style={{...webStyle.fontFamily,textAlign:"left"}}>{row.attributes?.brand}</TableCell>
                <TableCell style={{...webStyle.fontFamily,textAlign:"left",
                }}>
                    <span style={{borderRadius:"16px",
                    padding:"4px 10px",
                    background:"rgba(239, 238, 233, 1)"}}>
                    {row.attributes?.status?.charAt(0).toUpperCase() + row.attributes?.status?.slice(1)}
                    </span>
                    </TableCell>
                    <TableCell style={{color:"rgba(83, 80, 63, 1)",fontSize:"22px",gap:"20px",textAlign:"center"}}>
                        <DeleteOutlineOutlinedIcon data-test-id={`deleteIcon${index}`} onClick={()=>this.handleOpenConfirmationModal(row.attributes?.id)} style={{cursor:"pointer"}} /> <VisibilityOutlinedIcon style={{cursor:"pointer"}}/>
                    </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
            <CustomPagination
            data-test-id="paginationTest"
          totalPages={this.state.metaData?.total_pages}
          data={this.state.tabelData}
          rowsPerPage={this.state.perPageData}
          page={this.state.page-1}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </TableContainer>:this.NotFound()}
      </div>
      {this.confirmationModal()}
      {this.state.openFilter &&(
        <Backdrop
          open={true}
          onClick={this.handleFilter}
          sx={{
            zIndex: 1299,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
        />
      )}
       <Slide direction="right" in={this.state.openFilter} mountOnEnter unmountOnExit>
        <Box
        data-test-id="sliderDiv"
          sx={{
            overflowY:"hidden",
            position: "fixed",
            top: 0,
            left: 0,
            width: "25%",
            height: "100%",
            boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
            zIndex: 1300,
            display: "flex",
            flexDirection: "column",
            alignItems:"center",
            borderTopRightRadius: "25px", 
            borderBottomRightRadius: "25px",
            padding:"14px",
            boxSizing:"border-box",
            background:"rgba(239, 238, 233, 1)"
          }}
        >
          <Box sx={{overflowX:"auto",marginRight:"-12px",paddingRight:"12px",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f0f0f0", // Track color
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(83, 80, 63, 1)", // Thumb color
                borderRadius: "4px",
                height:"10px"
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(83, 80, 63, 1)", // Thumb color on hover
              },
          }}>

    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"9px 0px"}}>
         <Typography 
         className="montserratFont" 
         variant="h6" 
         sx={{ color: 'rgba(83, 80, 63, 1)',
         fontWeight: 600, fontSize: '16px'}}>
            Filters
          </Typography>
          <Button
            color="error"
            className="montserratFont"
            data-testid="clearBtnTestId"
            onClick={this.handleCancelFilter}
            sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(255, 61, 61, 1)', textTransform: 'capitalize',":hover":{background:"transparent"} }}
          >
            Clear All
          </Button>
         </div>
         <hr style={{marginTop:"0px",width:"100%",backgroundColor:"rgba(83, 80, 63, 1)",border:"none",height:"1px"}} />
         {this.categoryDiv()}
         {this.brandDiv()}
         
         {this.StatusDiv()}
         {this.DateDiv()} 
           
            </Box>
            <div style={{padding:"0px 40px",boxSizing:"border-box",marginTop:"30px",width:"100%",display:"flex",justifyContent:"space-between",position:"absolute",bottom:"30px"}}>
            <FooterButton data-test-id="cancelBtnTest"  style={{fontSize: '18px',fontWeight:600,backgroundColor:"#EFEEE9",color:"#53503F",border:"1px solid #53503F"}} onClick={this.handleCancelFilter}>Cancel</FooterButton>
            <FooterButton data-test-id="applyBtnTest" style={{fontSize: '18px',backgroundColor:"#53503F"}}  onClick={this.handleApplyBtn}>Apply</FooterButton>
            </div>
        </Box>
      </Slide>
     
        </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const IconWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    paddingLeft:"-10px",
    backgroundColor: "rgba(237, 230, 220, 0.5)",
  });
const MyTextField = styled(TextField)({
    width: "406px",
    boxSizing: "border-box",
    height: "44px",
    justifyContent: "center",
    padding: "10px 8px",
    gap: 8,
    borderRadius: "4px",
    borderWidth: "0.2px 0 0 0",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    margin:"25px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        width: "100%",
      },
    },
  });
  const FooterButton = styled("button")({
    width: "155px",
    height: "56px",
    padding: "16px 0px",
    borderRadius: "4px",
    color: "white",
    border: "none",
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "18px",
    cursor: "pointer",
  });
  const CancelRemoveButtonBox=styled(Box)({
    display:'flex',
    alignItems:'center',
    gap:'50px',
    marginTop:'40px',
    '@media (max-width:420px )': {
        gap:'28.45px',
    },
    '@media (max-width:359px )': {
        flexDirection:'column'
    },
  });
  const RemoveCartModalBox=styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.89)',
    width: '600px',
    borderRadius: '4px',
    padding: '68px 49px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#EFEEE9',
    height: '340px',
    justifyContent: 'center',
    maxHeight: '340px',
    alignItems: 'center',
    
    "& .areYouSureText": {
        fontSize: '20px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 400,
        color: '#53503F',
        marginTop:'8px',
        maxWidth:'340px',
        textAlign:"center"
    },
    "& .crossIconBox": {
        position: 'absolute',
        right: '28px',
        top: '28px',
        cursor:'pointer'
    },
    "@media  (max-width:550px)": {
        width: 'unset',
    },
    '@media (max-width:400px )': {
        padding: '68px 25px',
  
    },
    '@media (max-width:362px )': {
        maxHeight: 'unset',
        height: 'unset',
    },
    '@media (max-width:359px )': {
        width: '100%',
    },
  });
  const ReactSelect = styled(Select)({
    minWidth: '215px',
    width:"auto",
    borderRadius: "8px",
    outline: 'none',
    height:'44px',
    scrollbarGutter:'stable',
   
    '& .custom-select__menu-list': {
      scrollbarWidth: '5px',
      scrollbarColor: '#53503F transparent',
      direction: 'ltr',
      borderRadius: '8px',
      overflow: 'visible',
    },
    '& .custom-select__menu-list::-webkit-scrollbar': {
      scrollbarWidth: '5px',
      scrollbarColor: '#53503F transparent',
      direction: 'ltr',
    },
    '& .custom-select__menu-list::-webkit-scrollbar-thumb': {
      scrollbarColor: '#53503F transparent',
      scrollbarWidth: '5px',
      direction: 'ltr',
      borderRadius: '8px',
    },
    '& .custom-select__menu-list:hover': {
      scrollbarWidth: '5px',
      direction: 'ltr',
      scrollbarColor: '#53503F transparent',
      borderRadius:'8px'
    },
  
    "& .custom-select__menu-list span": {
      display: 'inline-block',
      paddingLeft: '8px',
    },
  
    "& .css-1uccc91-singleValue": {
      color: "#53503F",
      fontWeight: 600,
      fontSize: '16px',
      fontFamily: "'Montserrat', sans-serif",
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: 'none'
    },
    "& .css-yk16xz-control": {
      border: 'none',
      borderColor: 'none',
      height:'100%',
    },
    "& .css-yk16xz-control:hover": {
      border: 'none',
      borderColor: 'none'
    },
    "& .css-g1d714-ValueContainer": {
      height: '44px',
      border: 'none'
    },
    "& .css-1pahdxg-control": {
      borderColor: 'transparent !important',
      outline: 'none !important',
      boxShadow: 'none !important',
      border: 'none !important',
      height:'44px',
      minHeight:'44px'
    },
    "& .custom-select__control": {
      minHeight: '44px',
      height: '44px',
    },
    "& .custom-select__value-container": {
      height: '44px',
    },
    "& .css-1wa3eu0-placeholder": {
      color: '#BABABA',
      fontWeight: 400,
      opacity: 1,
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '16px',
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
      width: '24px',
      display: 'flex',
      justifyContent: "center"
    },
    "& .css-tlfecz-indicatorContainer": {
      padding: '0 10px'
    },
    "& .css-6q0nyr-Svg": {
      color: '#53503F',
      cursor:'pointer'
    },
    "& .css-26l3qy-menu": {
      fontWeight: 400,
      color: '#3C3E49',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '12px',
      zIndex: 1000
    },
    "& .css-9gakcf-option": {
      backgroundColor: '#53503F',
      fontWeight: 600,
      color: '#FFFFFF',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '12px',
    },
    "& span": {
      display: 'inline-block',
    },
  
    "@media (max-width: 400px)": {
      minWidth: 'unset'
    }
  });
  const CancelButton=styled(Button)({
    width: '155px',
    border:'1px solid rgba(83, 80, 63, 1)',
    backgroundColor: 'transparent',
    height: '56px',
    borderRadius: '4px',
    textTransform: 'none',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: '18px',
    cursor: 'pointer',
    color: '#53503F',
    "&:hover": {
        backgroundColor: 'transparent',
    },
  });
  const RemoveButton=styled(Button)({
    width: '155px',
    height: '56px',
    backgroundColor: '#53503F',
    textTransform: 'none',
    borderRadius: '4px',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    color: '#EDE6DC',
    cursor: 'pointer',
    "&:hover": {
        backgroundColor: '#53503F',
    },
  })
  const StyledSearchIcon = styled(SearchIcon)({
    width: "17.5px",
    height: "17.5px",
  });
const webStyle = {
    confirmation: {
        fontSize: '22px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
    },
    fontFamily:{
        fontFamily: "'Montserrat', sans-serif",
        color:"rgba(83, 80, 63, 1)",
        align:"center",
        fontSize:"16px"
    },
    textCenter:{
        textAlign:"center"
    }
};
// Customizable Area End