import React, { useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {useNavigate} from 'react-router-dom'
export interface IProps {
  categoriesList: any[];
  getCategoriesOnHover: (categoryID: string | number) => void;
  categoriesOnHoverList: any;
  handleCategories: any;
  handleMoveToHome: any;
}

// Styled Components
const TabContainer = styled(Box)({
  boxSizing: "border-box",
  width: "95%",
  margin: "44px 36px 10px 40px",
  backgroundColor: "#EDE6DC",
  position: "relative",
  boxShadow: "0 0.35px 0 0 rgba(83, 80, 63, 1)",
  display: "flex",
  justifyContent:"center",
  gap:"12px"
});

const StyledTab = styled(Box)(({ theme }) => ({
  position: "relative",
  transition: "color 0.2s",
  boxSizing: "border-box",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  color:"#53503F",
  letterSpacing: "0.25px",
  textAlign: "left",
  padding: "12px 16px",
  cursor: "pointer",
  "&:hover": {
    color: "#53503F",
  },
  textTransform:"uppercase",
  pointerEvents: "all",
  display: "inline-block",
}));

const Underline = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  height: "2px",
  backgroundColor: "#53503F",
  transition: "width 0.2s, left 0.2s",
  zIndex: 1,
}));

const TabContentWrapper = styled(Box)({
  width: "120%",
  backgroundColor: "#EDE6DC",
  padding: "16px",
  marginLeft: "30px",
  position: "absolute",
  top: "100%",
  left: "-30px",
  zIndex: 80,
});

const TabContent = styled(Box)({
  display: "flex",
  gap: "30px",
});

const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
});

const Header = styled(Typography)({
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "18px",
  fontWeight: 600,
  margin: "5px 20px",
  width: "200px",
  lineHeight: "16px",
  letterSpacing: "0.01em",
  textAlign: "left",
  color: "rgba(83, 80, 63, 1)",
});

const CustomTypography = styled(Typography)({
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "14px",
  fontWeight: 400,
  width: "200px",
  margin: "5px 20px",
  lineHeight: "16px",
  letterSpacing: "0.01em",
  textAlign: "left",
  color: "#53503F",
  cursor:"pointer"
});

// Main Component
const FullWidthTabComponent: React.FC<IProps> = (props) => {
  const { categoriesList, getCategoriesOnHover, categoriesOnHoverList,handleCategories, handleMoveToHome } = props;
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [activeTabId, setActiveTabId] = useState<string | number>(0);
  const [isHoveringTab, setIsHoveringTab] = useState<boolean>(false);
  const [isHoveringContent, setIsHoveringContent] = useState<boolean>(false);

  const [underlineStyle, setUnderlineStyle] = useState<{
    width: string;
    left: string;
  }>({ width: "0px", left: "0px" });
  const tabRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [tabForCategories, setTabForCategories] = useState<string | null>(null);
  const navigate =useNavigate()

  const handleItemId = (id: Number) => {
    navigate(`/ProductDescription?productId=${id}`);
  }

  // Function to generate content for each tab
  const getTabContent = (tab: string): JSX.Element | null => {
    return tab !== "New Arrivals" ? (
      <TabContent style={{ width: "100%" }}>
        {/* Men Section */}
        {categoriesOnHoverList?.["Men"] && categoriesOnHoverList["Men"].length > 0 && (
          <Section>
            <Header>Men</Header>
            {categoriesOnHoverList["Men"]?.map((item: any, index: any) => (
              <CustomTypography key={index} onClick={() => handleItemId(item.catalogue_id)}>{item?.name}</CustomTypography>
            ))}
          </Section>
        )}
        {/* Women Section */}
        {categoriesOnHoverList?.["Women"] && categoriesOnHoverList["Women"].length > 0 && (
          <Section>
            <Header>Women</Header>
            {categoriesOnHoverList["Women"]?.map((item: any, index: any) => (
              <CustomTypography key={index} onClick={() => handleItemId(item.catalogue_id)}>{item?.name}</CustomTypography>
            ))}
          </Section>
        )}
  
        {/* New Arrivals Section */}
        {categoriesOnHoverList?.["Brands"] && categoriesOnHoverList["Brands"].length > 0 && (
          <Section>
            <Header>Brands</Header>
            {categoriesOnHoverList["Brands"]?.map((item: any, index: any) => (
              <CustomTypography key={index}>{item?.name}</CustomTypography>
            ))}
          </Section>
        )}
        <div style={{display:"flex",gap:"20px",marginRight:"100px",color:"#53503F",}}>
        {categoriesOnHoverList["Brands"]?.map((item: any, index: any) => (
             index<2 && (
            <div style={{display:"flex",flexDirection:"column",gap:"10px",alignItems:"center",justifyContent:"center"}}>

             <img style={{borderRadius:"4px"}} width={175} height={193} src={item.image_url} key={index}/>
             <span style={{fontWeight:600,fontSize:"14px"}}>{item?.name}</span>
             <span className="bigCaslon" onClick={()=>handleCategories(activeTab,activeTabId)} 
             style={{cursor:"pointer",
              fontWeight:600,
              display:"flex",
              alignItems:"center",
              color:"#53503F",
              fontSize:"12px",
              textDecoration:"underline",
              fontFamily: "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
             }}>EXPLORE MORE 
             <KeyboardArrowRightIcon style={{fontSize:"13px"}}/>
             </span>
            </div>
             )
            ))}

        </div>
      </TabContent>
    ) : null;
  };
  
  const handleMouseEnterTab = (tab: string, catId: string | number) => {
    const ref = tabRefs.current[tab];
    if (ref) {
      const tabRect = ref.getBoundingClientRect();
      const tabsRect = (
        ref.parentElement as HTMLElement
      ).getBoundingClientRect();
      setUnderlineStyle({
        width: `${tabRect.width}px`,
        left: `${tabRect.left - tabsRect.left}px`,
      });
    }
    setActiveTab(tab);
    setActiveTabId(catId);
    getCategoriesOnHover(catId);
    setIsHoveringTab(true);
  };

  const handleMouseLeaveTab = () => {
    setIsHoveringTab(false);
  };

  const handleMouseEnterContent = () => {
    setIsHoveringContent(true);
  };

  const handleMouseLeaveContent = () => {
    setIsHoveringContent(false);
  };

  useEffect(() => {
    // Delay hiding the content when both tab and content are not hovered
    const timer = setTimeout(() => {
      if (!isHoveringTab && !isHoveringContent) {
        setActiveTab(null);
        setUnderlineStyle({ width: "0px", left: "0px" });
      }
    }, 200);

    return () => clearTimeout(timer);
  }, [isHoveringTab, isHoveringContent]);

  return (
    <Box
      sx={{ maxWidth: "100%", width: "100%", boxSizing: "border-box" }}
      position="relative"
    >
      <TabContainer>
        <StyledTab
          key={"New Arrivals"}
          onMouseEnter={() => handleMouseEnterTab("New Arrivals", "0")}
          onMouseLeave={handleMouseLeaveTab}
          ref={(el) => {
            tabRefs.current["New Arrivals"] = el as HTMLDivElement | null;
          }}
          onClick={handleMoveToHome}
        >
          New Arrivals
        </StyledTab>

        {categoriesList?.map((tab: any) => (
          <StyledTab
            key={tab?.attributes?.name}
            onMouseEnter={() =>
              handleMouseEnterTab(tab?.attributes?.name, tab?.attributes?.id)
            }
            onMouseLeave={handleMouseLeaveTab}
            ref={(el) => {
              tabRefs.current[tab?.attributes?.name] =
                el as HTMLDivElement | null;
            }}
            onClick={() => handleCategories(tab?.attributes?.name, tab?.attributes?.id)}
          >
            {tab?.attributes?.name}
          </StyledTab>
        ))}
        <Underline
          style={{ width: underlineStyle.width, left: underlineStyle.left }}
        />
      </TabContainer>

      {activeTab && (
        <TabContentWrapper
          onMouseEnter={handleMouseEnterContent}
          onMouseLeave={handleMouseLeaveContent}
        >
          {getTabContent(activeTab)}
        </TabContentWrapper>
      )}
    </Box>
  );
};

export default FullWidthTabComponent;
