import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData} from "../../../framework/src/Utilities";
import { isValidPhoneNumber } from "react-phone-number-input";
import { convertToBase64 } from '../../../components/src/commanMethods';
export const configJSON = require("./config");
import dayjs ,{ Dayjs } from 'dayjs';
import {profile} from "./assets"
import JSZip from "jszip"
import {saveAs} from "file-saver"
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface State {
  [key : string]:boolean|string|unknown
}
type NullUndefinedInterFace = string | undefined |null

export interface ProFileInterface {
  id: number|null,
  type: string,
  attributes: {
      avatar_url: string
      country_code: string
      country_name: string
      date_of_birth: string
      deactivated_at: string
      email: string
      first_name: string
      full_phone_number: string
      gender: string
      is_deactivated: boolean
      last_name: string
      phone_number: string
      role_name: string
      unique_auth_id: string
      commercial_number?:string
      address?:string
      postal_code?:string
      city?:string
      commercial_documents_list?:[{url:string,blob_key:string,id:number}]
  }
}
export interface CountryInterArr{
value?: number|string,
label?: string,
countryCode?:string,
country_id?:number,
contCode?:number
}
export interface CountryInterFace {
id:number,
attributes:{name:string,alpha2: string,country_code:number,id:number}
value: number,
label: string,
countryCode: string,
}
// Customizable Area End

interface S {
  // Customizable Area Start
  profileData:ProFileInterface
  editModalOpen:boolean
  first_Name: string
  last_Name: string
  email: string
  emailErr:boolean
  phone_Number: string
  location: string
  date_of_Birth: string|Dayjs|null
  date_of_birthErr:boolean
  date_of_birthErrText:string
  new_phone_numberErr:boolean
  new_phone_numberErrText:string
  phoneNumber:string
  country_id: CountryInterArr|undefined|null,
  countryListArray:CountryInterArr[],
  gender:string,
  calendarOpen:boolean
  profileImage:string
  base64Image:File|string;
  successPopupOpen:boolean;
  successMessage:string;
  commercial_registration_number:string;
  previewImagesArray:{ url: string; id: number|null ,blob_key:string}[]
  cartData:[]
  shopperProfileUpdatedModal:boolean;
  permitDocumentDownloadModal:boolean;
  totalFiles:number[]
  commercialCertificateErr:boolean
  commercialCertificateErrText:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserProfileBuyerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  profileApi?:string
  countryListApi?:string
  updateProfileApi?:string
  editProfile?:string
  cartDataApiCallId?:string
  editShopperProfile?:string
  directUploadApi?:string
  directDeleteFileApi?:string
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profileData: {
        id: null,
        type: '',
        attributes: {
          avatar_url: '',
          country_code: '',
          country_name: '',
          date_of_birth: '',
          deactivated_at: '',
          email: '',
          first_name: '',
          full_phone_number: '',
          gender: '',
          is_deactivated: false,
          last_name: '',
          phone_number: '',
          role_name: '',
          unique_auth_id: '',
        }
      },
        editModalOpen:false,
        first_Name:'',
        last_Name:'',
        email:'',
        emailErr:false,
        phone_Number:'',
        location:'',
        date_of_Birth:'',
        phoneNumber:'',
        new_phone_numberErr:false,
        new_phone_numberErrText:'',
        country_id:null,
        countryListArray:[],
        gender:'',
        calendarOpen:false,
        date_of_birthErr:false,
        date_of_birthErrText:'',
        profileImage:'',
        base64Image:'',
        successPopupOpen:false,
        successMessage:'',
        commercial_registration_number:'',
        previewImagesArray:[],
        cartData:[],
        shopperProfileUpdatedModal:false,
      permitDocumentDownloadModal: false,
      totalFiles: [],
      commercialCertificateErr: false,
      commercialCertificateErrText: ''
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.handleFetchProfile()
    this.handleFetchCountryList()
    this.handleFetchCartData()
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson?.errors) {
      switch(apiRequestCallId){
        case this.profileApi:
          this.handleChangeSelect({ label: responseJson?.data?.attributes?.country_name, countryCode: responseJson?.data?.attributes?.country_code }, 'country_id')
          this.setState({
            first_Name: responseJson?.data?.attributes?.first_name,
            last_Name: responseJson?.data?.attributes?.last_name,
            phoneNumber: this.handlePhoneNumberAutoFill(responseJson?.data?.attributes?.full_phone_number),
            email: responseJson?.data?.attributes?.email,
            gender: responseJson?.data?.attributes?.gender,
            profileData: responseJson.data,
            date_of_Birth: dayjs(responseJson?.data?.attributes?.date_of_birth),
            profileImage:this.handleAvatar()?responseJson?.data?.attributes?.avatar_url: profile,
            commercial_registration_number:responseJson?.data?.attributes?.commercial_number,
            previewImagesArray:responseJson?.data?.attributes?.commercial_documents_list,
            totalFiles:this.hanldeReturnId(responseJson?.data?.attributes?.commercial_documents_list)
          })
          break;
        case this.countryListApi: this.handeCountryArray(responseJson?.data)
          break;
        case this.updateProfileApi: this.handleFetchProfile()
          break;
        case this.editProfile:
          this.handleFetchProfile()
          this.setState({editModalOpen:false, successMessage:responseJson?.meta?.message,successPopupOpen: true})
          break;
        case this.cartDataApiCallId: this.setState({ cartData: responseJson?.data?.attributes?.order_items })
          break;
        case this.editShopperProfile: this.handleFetchProfile()
          this.setState({ editModalOpen: false, successMessage: responseJson?.meta?.message, shopperProfileUpdatedModal: true,totalFiles:[] })
          break;
        case this.directUploadApi: this.handleTotalFiles(responseJson?.blob,responseJson?.blob?.id)
          break;
      }
     
    }
    if (responseJson && responseJson?.errors) {
      if(apiRequestCallId===this.editProfile){
        
        const fieldErrors: Partial<State> ={}
        responseJson?.errors.forEach((errorObj:{})=>{
         Object.entries(errorObj).forEach(([field,message])=>{
            fieldErrors[`${field}Err`] = true
            fieldErrors[`${field}ErrText`] = message
         })
        })
       this.setState((prevState)=>({
        ...prevState,
        ...fieldErrors
       }))
      }
      if (apiRequestCallId === this.editShopperProfile) {
        this.setState({
          commercialCertificateErr: true,
          commercialCertificateErrText: responseJson?.errors[0]?.commercial_certificates
        })
      }
    }
    
    // Customizable Area End
  };

  // Customizable Area Start
  handleFetchProfile = async ()=> {
    const tokenIs= await getStorageData('loginToken')

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      ...(tokenIs&&{token: tokenIs})
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileEndPointApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleFetchCountryList = () => {
    const requestMessageCountryList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryListApi = requestMessageCountryList.messageId;
    requestMessageCountryList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageCountryList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryListEndPoint
    );
    runEngine.sendMessage(requestMessageCountryList.id, requestMessageCountryList);

  }
  handeCountryArray = (data: CountryInterFace[]) => {
    const newData = data.map((element: CountryInterFace) => ({
      value: element.id,
      label: element?.attributes.name,
      countryCode: element?.attributes?.alpha2,
      country_id: element?.attributes?.id,
      contCode:element?.attributes?.country_code
    }));
    this.setState({ countryListArray: newData });
  }
  handleChangePhoneNumber = (event: string | undefined) => {
    if (event) {
      this.setState({ phoneNumber: event,new_phone_numberErrText:'', new_phone_numberErr: !isValidPhoneNumber(event) })
    }
  }
  handleChangeSelect = (event: CountryInterArr | {} | undefined | null, state: string) => {
    this.setState((prevState) => ({ ...prevState, [state]: event, ...(state === 'country_id' && { phoneNumber: '', phoneNumberErr: false }) }))
  }
  handleOpenModal = () => {
 
    const profileData=this.state.profileData?.attributes
    this.handleChangeSelect({ label: profileData?.country_name, countryCode: profileData?.country_code }, 'country_id')
    this.setState({ 
      editModalOpen: true,
      date_of_birthErrText:'',
      date_of_birthErr:false ,
      new_phone_numberErr:false,
      emailErr:false,
      first_Name: profileData?.first_name,
      last_Name: profileData?.last_name,
      phoneNumber: this.handlePhoneNumberAutoFill(profileData?.full_phone_number),
      email: profileData?.email,
      gender: profileData?.gender,
      date_of_Birth: dayjs(profileData?.date_of_birth),
      profileImage:this.handleAvatar()?profileData?.avatar_url: profile
    })
   
  }
  handleCalendarIconClick = () => {
    this.setState((prevState) => ({ calendarOpen: !prevState?.calendarOpen })
    )
  };
  handleChangeInput = (value: string, state: string) => {
    if(state=='email'){
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
     if(emailRegex.test(value)){
      this.setState((prevState) => ({ ...prevState, [state]: value,emailErr:false}))
    }else{
       this.setState((prevState) => ({ ...prevState, [state]: value,emailErr:true }))
     }
    }else{
      this.setState((prevState) => ({ ...prevState, [state]: value }))
    }
  }

  handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      this.setState({ profileImage: imageUrl,base64Image:file })
    }
  }
  handleUpdateProfile = async (event: React.ChangeEvent<HTMLInputElement>, profileId: number | null) => {
    const fileIs = event?.target?.files?.[0]
    if (fileIs) {
      const tokenIs = await getStorageData('loginToken')
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        ...(tokenIs && { token: tokenIs })
      };
      const base64Image = await convertToBase64(fileIs);
      const body = {
        "data": {
        "first_name": this.state.profileData?.attributes?.first_name,
        "last_name":  this.state.profileData?.attributes?.last_name,
        "encode_profile_image": {
            "content_type": fileIs?.type,
            "data":base64Image
          }}
      }
      const requestMessageUpdate = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateProfileApi = requestMessageUpdate.messageId;
      requestMessageUpdate.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );
      requestMessageUpdate.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateProfileApiEndPoint(profileId)
      );
      requestMessageUpdate.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageUpdate.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      runEngine.sendMessage(requestMessageUpdate.id, requestMessageUpdate);
    }
  }

  handleSubmitEditProfile = async () => {
    if (!this.state.new_phone_numberErr&&!this.state.emailErr) {
      let base64Image
      if (this.state.base64Image !== '') {
        base64Image = await convertToBase64(this.state.base64Image);
      }
      const tokenIs = await getStorageData('loginToken')
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        ...(tokenIs && { token: tokenIs })
      };
      const body = {
        "data": {
        "first_name": this.state.first_Name,
        "last_name": this.state.last_Name,
        "date_of_birth": this.state.date_of_Birth,
        "location_1": this.state.country_id?.country_id,
        ...(this.state.phoneNumber?.trim() !== '' && { "new_phone_number": this.state.phoneNumber }),
        ...(this.state.base64Image !== '' && {"encode_profile_image": {"content_type": "image/jpg","data": base64Image} }),
        "gender": this.state.gender,
        "new_email": this.state.email,
        }
      }
      const requestMessageUpdate = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editProfile = requestMessageUpdate.messageId;
      requestMessageUpdate.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );
      requestMessageUpdate.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateProfileApiEndPoint(this.state.profileData.id)
      );
      requestMessageUpdate.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageUpdate.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      runEngine.sendMessage(requestMessageUpdate.id, requestMessageUpdate);
    }
  }
  handleFetchCartData = async () => {
    const cartId=await getStorageData('cartId')
    const tokenIs= await getStorageData('loginToken')

    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      ...(tokenIs&&{token: tokenIs})
     
    }
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cartDataApiCallId = requestMessage1.messageId;

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointCart(cartId)
    );
    

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  }


  handleDisable = () => {
    const first_Name = this.state.first_Name == ''
    const last_name = this.state.last_Name == ''
    const country = this.state.country_id == null
    const email = this.state.date_of_Birth == ''
    const dateOfBirth = this.state.date_of_Birth == ''
    const genderIs = this.state.gender == ''

    if (first_Name || last_name || country || email || dateOfBirth || genderIs) {
      return true
    } else {
      return false
    }
  }
  handleAvatar=()=>{
    if(this.state.profileData.attributes.avatar_url ==''||this.state.profileData.attributes.avatar_url ==null){
        return false
    }else{
      return true
    }
     
  }
  handlePhoneNumberAutoFill=(phoneNumber:string|null)=>{
    if(phoneNumber ==''||phoneNumber ==null){
        return ''
    }else{
      return `+${phoneNumber}`
    }
     
  }
  handleReturnText = (address:NullUndefinedInterFace,postal_code:NullUndefinedInterFace,city:NullUndefinedInterFace ) =>{
      
     let addressIs=address
     if(postal_code!==undefined&&postal_code!==null){
      addressIs=addressIs+','+' '+postal_code
     }
     if(city!==undefined&&city!==null&&postal_code!==undefined&&postal_code!==null){
      addressIs=addressIs+' '+city
     }
     if(city!==undefined&&city!==null&&postal_code&&postal_code!==''){
      addressIs=addressIs+','+' '+city
     }
     return addressIs
  }

  handleDrop =(event:React.DragEvent<HTMLDivElement>) =>{
   event?.preventDefault()
   const file = event?.dataTransfer?.files
   this.handleFile(file)
  }
  handleBrowse = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files
    if (file&&file[0]) {
      this.handleFile(file)
    }

  }
  handleFile = (files: FileList) => {

    const newPreviews = [...this.state.previewImagesArray];
    Array.from(files).forEach((file) => {
      if(file.type === "application/pdf"){
        const pdfUrl = URL.createObjectURL(file)
        this.directUpload(file.name,file.type,pdfUrl)
      } else {
        const fileReader = new FileReader();
        fileReader.onload = (event: ProgressEvent<FileReader>) => {
        this.directUpload(file.name,file.type,event.target?.result)
        };
        fileReader.readAsDataURL(file)
      }
     
    });

  }
  handleCrossIconRemove = (index:number,blob_key:string,idIs:number|null) =>{
      let filteredArray = this.state.previewImagesArray.filter((element,indexIs)=>index!==indexIs) 
      let filteredTotal = this.state.totalFiles.filter((element)=>element!==idIs) 
      this.setState({previewImagesArray:filteredArray,totalFiles:filteredTotal,commercialCertificateErr:false,commercialCertificateErrText:''})
      this.handleDirectRemove(blob_key)
  }
  handleDirectRemove=async(blob_key:string)=>{
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      ...(tokenIs && { token: tokenIs })
    };
    const requestMessageUpdate = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.directDeleteFileApi= requestMessageUpdate.messageId;
    requestMessageUpdate.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    requestMessageUpdate.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.directRemoveApiEndPoint(blob_key)
    );
    requestMessageUpdate.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    runEngine.sendMessage(requestMessageUpdate.id, requestMessageUpdate);
  
  }
  directUpload = async (fileName: string, fileType: string, imagePath: string | ArrayBuffer | null | undefined) => {
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      ...(tokenIs && { token: tokenIs })
    };

    const body = {
      "platform_type": "desktop",
      "blob": imagePath,
      "filename": fileName,
      "file_type": fileType
    }
    const requestMessageUpdate = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.directUploadApi = requestMessageUpdate.messageId;
    requestMessageUpdate.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    requestMessageUpdate.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.directUploadApiEndPoint
    );
    requestMessageUpdate.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageUpdate.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageUpdate.id, requestMessageUpdate);


  }
  handleTotalFiles = (blob:{key:string,id:number,image_url:string},blobId: number) => {
    let prevImages = [...this.state.previewImagesArray]
    prevImages.push({ url: blob?.image_url, id: blob?.id, blob_key: blob?.key })

    let total = [...this.state.totalFiles]
    total.push(blobId)
    this.setState({ totalFiles: total,previewImagesArray:prevImages })
  }
  hanldeReturnId = (arrayList: [{ id: number }]) => {
    let newArr = []
    for (let i = 0; i <= arrayList.length - 1; i++) {
      newArr.push(arrayList[i]?.id)
    }
    return newArr
  }
  handleShopperProfileSave = async() => {
      
    if (!this.state.new_phone_numberErr&&!this.state.emailErr) {
      const tokenIs = await getStorageData('loginToken')
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        ...(tokenIs && { token: tokenIs })
      };
      const body = {
        "data": {
        "first_name": this.state.first_Name,
        "last_name": this.state.last_Name,
        "location_1": this.state.country_id?.country_id,
        ...(this.state.phoneNumber?.trim() !== '' && { "new_phone_number": this.state.phoneNumber }),
        "new_email": this.state.email,
        ...(this.state.totalFiles.length>0&&{"commercial_certificates" : this.state.totalFiles}),
        "commercial_number":this.state.commercial_registration_number
        }
      }
      const requestMessageShopperProfileUpdate = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editShopperProfile = requestMessageShopperProfileUpdate.messageId;
      requestMessageShopperProfileUpdate.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );
      requestMessageShopperProfileUpdate.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateProfileApiEndPoint(this.state.profileData.id)
      );
      requestMessageShopperProfileUpdate.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageShopperProfileUpdate.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      runEngine.sendMessage(requestMessageShopperProfileUpdate.id, requestMessageShopperProfileUpdate);
    }  
  }
   downloadZipFile = async ()=>{
     let ImagesUrlArr = this.state.previewImagesArray?.map(({ url }) => url)
     if (ImagesUrlArr.length > 0) {
       this.setState({ permitDocumentDownloadModal: true })
       const zipFile = new JSZip()
       const folder = zipFile.folder("images");

       const fetchImages = async (url: string) => {
         const response = await fetch(url);

         const blob = await response?.blob();

         return blob;

       };
       for (const [index, url] of ImagesUrlArr?.entries()) {
         const blob = await fetchImages(url);
         folder?.file(`image-${index + 1}.jpg`, blob);
       }

       const content = await zipFile?.generateAsync({ type: "blob" });
       saveAs(content, "images.zip");

     }
  };
  handleOpenDownloadFolder = () => {
    this.setState({ permitDocumentDownloadModal: false })
  }
 
  // Customizable Area End
}