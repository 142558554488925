import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  landingPagesData:any;
  categoriesArray:any[];
  categoriesOnHoverList:any;
  brandsAndCataloguesList:any;
  error:boolean;
  scrollStates: {
    [key: number]: {
      canScrollLeft: boolean;
      canScrollRight: boolean;
    };
  };
  categoryId: number,
  categoryName: string,
  categoryData: any[],
  allFilter: any,
  showCategoryPage: boolean,
  filterData: any,
  gender: any
  searchQuery: any,
  openLoginModal:boolean,
  cartLength:number,
  openMakeWishModal:boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LandingPageWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getLandingPagesApiCallId:string="";
  getCategoriesListApiCallId:string="";
  getHoverCategoriesList:string="";
  getBrandsAndCatalouguesDataId:string=""
  containerRefs: { [key: number]: React.RefObject<HTMLDivElement> } = {};
  categoryCallId: string="";
  footerLinksApiId:string="";
  cartDataApiCallId:string="";
  addtoFavoriteApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      landingPagesData:[],
      categoriesArray:[],
      categoriesOnHoverList:{},
      brandsAndCataloguesList:{},
      error:false,
      scrollStates: {
        0: { canScrollLeft: false, canScrollRight: true },
        1: { canScrollLeft: false, canScrollRight: true },},
        categoryId: 0,
        categoryName: '',
        categoryData: [],
      allFilter: false,
      showCategoryPage: false,
      filterData: [],
      gender: '',
      searchQuery:'',
      openLoginModal:false,
      cartLength:0,
      openMakeWishModal:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const dataId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (this.getLandingPagesApiCallId === dataId) {
        this.handleResponse(this.getLandingPagesApiCallId, 'landingPagesData', message);
      } else if (this.getCategoriesListApiCallId === dataId) {
        this.handleResponse(this.getCategoriesListApiCallId, 'categoriesArray', message);
      } else if (this.getHoverCategoriesList === dataId) {
        this.handleResponse(this.getHoverCategoriesList, 'categoriesOnHoverList', message);
      } else if (this.getBrandsAndCatalouguesDataId === dataId) {
        this.handleResponse(this.getBrandsAndCatalouguesDataId, 'brandsAndCataloguesList', message);
      } else if (this.categoryCallId === dataId) {
        this.handleResponse(this.categoryCallId, 'categoryData', message);
      }else if(this.cartDataApiCallId==dataId){
        this.handleResponse(this.cartDataApiCallId, 'cartLength', message);
      }else if(this.addtoFavoriteApiCallId==dataId){
        if(responseJson.errors){
          this.openLoginModal()
        }else{
          this.getLandingPagedata()
        }
      }else if(dataId==this.footerLinksApiId){
        await setStorageData("footerLinks",JSON.stringify(responseJson))
      }
      
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    Object.keys(this.containerRefs).forEach((_, index) => {
      setTimeout(() => this.checkScrollButtons(index), 0);
    });
   this.getLandingPagedata()
    this.getCategoriesListApiCallId= this.apiCall("",{
      method:"GET",
      endPoint:"/bx_block_categories/categories"
  })
  this.getFooterLinks()
  this.getBrandsAndCatalouguesDataId= this.apiCall("",{
    method:"GET",
    endPoint:"/bx_block_catalogue/catalogues/new_arrivals"
})
this.fetchCartData()
  }
  getLandingPagedata=async()=>{
    const token=await getStorageData("loginToken")
    this.getLandingPagesApiCallId= this.apiCall(token,{
      method:"GET",
      endPoint:"/bx_block_content_management/landing_pages"
  })
  }

  getCategoriesOnHover=(categorID:string|number)=>{
    if(categorID!=0){
      this.getHoverCategoriesList=this.apiCall("",{
        method:"GET",
        endPoint:`/bx_block_categories/categories/categories_hover_options?category_id=${categorID}`
      })
    }
  }
  apiCall = (token: any,payload:any) => {
    let {method,endPoint}=payload
    const header = {
     'Content-Type': "application/json",
      token: token||"",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   return requestMessage.messageId;

  };
// Assign ref to the correct index
assignRef = (index: number) => {
  // Create a new ref if it doesn't exist
  if (!this.containerRefs[index]) {
    this.containerRefs[index] = React.createRef<HTMLDivElement>();
  }
  return this.containerRefs[index];
};


scrollLeft = (index: number) => {
  const ref = this.containerRefs[index];
  if (ref?.current) {
    ref.current.scrollBy({ left: index === 1 ? -700 : -200, behavior: 'smooth' });
    setTimeout(() => this.checkScrollButtons(index), 300);
  }
};
handleMakeWishModal=()=>{
  this.setState({openMakeWishModal:!this.state.openMakeWishModal})
}
scrollRight = (index: number) => {
  const ref = this.containerRefs[index];
  if (ref?.current) {
    ref.current.scrollBy({ left: index === 1 ? 700 : 500, behavior: 'smooth' });
    setTimeout(() => this.checkScrollButtons(index), 500); // Delay for smooth scroll
  }
};
checkScrollButtons = (index: number) => {
  const ref = this.containerRefs[index];
  if (ref.current) {
    const { scrollLeft, scrollWidth, clientWidth } = ref.current;

    this.setState((prevState) => ({
      scrollStates: {
        ...prevState.scrollStates,
        [index]: {
          canScrollLeft: scrollLeft > 0,
          canScrollRight: scrollLeft + clientWidth < scrollWidth, // Check if there's more content to the right
        },
      },
    }));
  }
};
handleResponse = (apiCallId:string, stateKey:any, message:any) => {
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

  if (responseJson.errors?.[0]) {
    this.setState({ error: true},()=>this.openLoginModal());
  }else if (responseJson && !responseJson.errors) {
    if(stateKey==="cartLength"){
      this.setState({cartLength:responseJson?.data?.attributes?.order_items?.length})
    }else {
      this.setState({ [stateKey]: responseJson.data || responseJson }as Pick<S, keyof S>);
    }
  }
};
handleAddToFavorite=async(cartId:number)=>{
    
  const bodyAddFav = {
    "wishlist": {
      "catalogue_variant_id": cartId
      }
  }
  const tokenIs= await getStorageData('loginToken')
  const headerIs = {
    "Content-Type": configJSON.exampleApiContentType,
     ...(tokenIs&&{token: tokenIs})
  };
  const requestMessageAddToFavorite = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.addtoFavoriteApiCallId = requestMessageAddToFavorite.messageId;
  requestMessageAddToFavorite.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  requestMessageAddToFavorite.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_wishlist/wishlists/add_favorite_or_remove`
  );
  requestMessageAddToFavorite.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headerIs)
  );
  requestMessageAddToFavorite.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(bodyAddFav)
  );
  runEngine.sendMessage(requestMessageAddToFavorite.id, requestMessageAddToFavorite);


}
handleCategoryData = (categoryName: any, categoryId: any) => {
  this.setState({showCategoryPage: true, categoryId: categoryId, categoryName: categoryName})
  this.categoryCallId=this.apiCall("",{
    method:"GET",
    endPoint:`bx_block_catalogue/catalogues/filtered_catalogues?category_id=${categoryId}`
  })
}
getFooterLinks = () => {
  this.footerLinksApiId=this.apiCall("",{
    method:"GET",
    endPoint:`/bx_block_categories/categories/social_media_tabs`
  })
}
closeLoginModal=()=>{
  this.setState({openLoginModal:false})
}
openLoginModal=()=>{
  this.setState({openLoginModal:true})
}

fetchCartData=async()=>{
  const cartId=await getStorageData('cartId')
  const tokenIs= await getStorageData('loginToken')
  this.cartDataApiCallId= this.apiCall(tokenIs,{
    method:"GET",
    endPoint:`bx_block_shopping_cart/orders/${cartId}`,
})
}

handleAllFilter = () => {
  this.setState({allFilter: true})
}

handleCloseAllFilter = () => {
  this.setState({allFilter: false})
}

handleCategoryVisibility = () => {
  this.setState({showCategoryPage: false})
}
navigateToDetailedProductView=(productId:string)=>{
  this.props.navigation.navigate("ProductDescription",{productId:productId});
}
handleApplyButton = (data: any) => {
  this.setState({filterData: data})
}

handlePassData = (data: any) => {
  this.setState({gender: data})
}

handleCategoryDataSearch = (categoryData: any, categoryId: any) => {
  this.setState({ categoryData, categoryId });
  this.setState({showCategoryPage:true})
};
  // Customizable Area End
}
