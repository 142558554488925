import React from "react";

// Customizable Area Start
import {
    styled,
    Typography,
    Box,
    Button,
    InputLabel,
    TextField,
    Modal,
    Grid,

} from "@mui/material";

import {

} from "@mui/material/styles";
import CloseIcon  from "@mui/icons-material/Close";
  import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
  import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
  import Select from "react-select";

import {makAWishImg} from "./assets"
const FooterButton = styled("button")({
  width: "360px",
  height: "56px",
  padding: "16px 0px",
  borderRadius: "4px",
  color: "white",
  border: "none",
  backgroundColor: "#53503F",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "18px",
  fontWeight: 600,
  cursor: "pointer",
});
const LabelText = styled(Typography)({
  fontFamily: "'Montserrat', sans-serif",
  color: '#53503F',
  fontWeight: 600,
  fontSize: '16px',
  marginBottom: '4px'
})
const ReactSelect = styled(Select)({
  minWidth: '327px',
  outline: 'none',
  height:'56px',
  scrollbarGutter:'stable',
 
  '& .custom-select__menu-list': {
      scrollbarWidth: '5px',  scrollbarColor: '#53503F transparent',  
      direction: 'ltr',
      borderRadius:'8px'
  },
  '& .custom-select__menu-list::-webkit-scrollbar': {
      scrollbarWidth: '5px',
      scrollbarColor: '#53503F transparent',
      direction: 'ltr',
  },
  '& .custom-select__menu-list::-webkit-scrollbar-thumb': {
      scrollbarColor: '#53503F transparent',
      scrollbarWidth: '5px',
      direction: 'ltr',
      borderRadius:'8px'
  },

  '& .custom-select__menu-list:hover': {
      scrollbarWidth: '5px',
      direction: 'ltr',
      scrollbarColor: '#53503F transparent',
      borderRadius:'8px'
    },
  "& .css-1uccc91-singleValue": {
      color: "#53503F",
      fontWeight: 600,
      fontSize: '16px',
      fontFamily: "'Montserrat', sans-serif",
  },
  "& .css-1okebmr-indicatorSeparator": {
      display: 'none'
  },
  "& .css-yk16xz-control": {
      border: 'none',
      borderColor: 'none',
      height:'100%',
  },
  "& .css-yk16xz-control:hover": {
      border: 'none',
      borderColor: 'none'

  },
  "& .css-g1d714-ValueContainer": {
      height: '56px',
      border: 'none'

  },
  "& .css-1pahdxg-control": {
      borderColor: 'transparent !important',
      outline: 'none !important',
      boxShadow: 'none !important',
      border: 'none !important',
      height:'56px',
      minHeight:'56px'
  },
  "& .custom-select__control": {
      minHeight: '56px',
      height: '56px',
    },
  "& .custom-select__value-container": {
      height: '56px',
  },
  "& .css-1wa3eu0-placeholder": {
      color: '#BABABA',
      fontWeight: 400,
      opacity: 1,
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '16px',
      
  },
  "& .css-1hb7zxy-IndicatorsContainer": {
      width: '24px',
      display: 'flex',
      justifyContent: "center"
  },
  "& .css-tlfecz-indicatorContainer": {
      padding: '0'
  },
  "& .css-6q0nyr-Svg": {
      color: '#53503F',
       cursor:'pointer'
  },
  "& .css-26l3qy-menu": {
      fontWeight: 400,
      color: '#3C3E49',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '12px',
      zIndex: 1000
  },
  "& .css-9gakcf-option": {
      backgroundColor: '#53503F',
      fontWeight: 600,
      color: '#FFFFFF',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '12px',
  },
  "& span":{
      display:'none'
  },
  "& svg":{
      cursor:'pointer',
      position: 'absolute',
      right: '8px',
      top: '20px',
      color:'#53503F'
  },

  "@media (max-width: 400px)": {
      minWidth: 'unset'
  }
})
const ScrollableContent = styled(Box)({
  height:"679px",
  overflowX:"auto",
  marginRight:"-20px",
  paddingRight:"7px",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f0f0f0", // Track color
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(83, 80, 63, 1)", // Thumb color
    borderRadius: "4px",
    height:"10px"
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(83, 80, 63, 1)", // Thumb color on hover
  },
  "@media (max-width: 1400px)": {
      height:"auto"
  }
});
const RemoveCartModalBox=styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(0.89)',
  width: '600px',
  borderRadius: '4px',
  padding: '68px 49px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#EFEEE9',
  height: '340px',
  justifyContent: 'center',
  maxHeight: '340px',
  alignItems: 'center',
  
  "& .areYouSureText": {
      fontSize: '20px',
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 400,
      color: '#53503F',
      marginTop:'8px',
      maxWidth:'340px',
      textAlign:"center"
  },
  "& .crossIconBox": {
      position: 'absolute',
      right: '28px',
      top: '28px',
      cursor:'pointer'
  },
  "@media  (max-width:550px)": {
      width: 'unset',
  },
  '@media (max-width:400px )': {
      padding: '68px 25px',

  },
  '@media (max-width:362px )': {
      maxHeight: 'unset',
      height: 'unset',
  },
  '@media (max-width:359px )': {
      width: '100%',
  },
});
const CancelRemoveButtonBox=styled(Box)({
  display:'flex',
  alignItems:'center',
  gap:'50px',
  marginTop:'40px',
  '@media (max-width:420px )': {
      gap:'28.45px',
  },
  '@media (max-width:359px )': {
      flexDirection:'column'
  },
});
const CancelButton=styled(Button)({
  width: '155px',
  border:'1px solid rgba(83, 80, 63, 1)',
  backgroundColor: 'transparent',
  height: '56px',
  borderRadius: '4px',
  textTransform: 'none',
  fontFamily: "'Montserrat', sans-serif",
  fontWeight: 600,
  fontSize: '18px',
  cursor: 'pointer',
  color: '#53503F',
  "&:hover": {
      backgroundColor: 'transparent',
  },
});
const RemoveButton=styled(Button)({
  width: '155px',
  height: '56px',
  backgroundColor: '#53503F',
  textTransform: 'none',
  borderRadius: '4px',
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '18px',
  fontWeight: 600,
  color: '#EDE6DC',
  cursor: 'pointer',
  "&:hover": {
      backgroundColor: '#53503F',
  },
})
// Customizable Area End

import MakeAWishWebcontroller, {
  Props,
  configJSON,
} from "./MakeAWishWebcontroller";

export default class MakeAWish extends MakeAWishWebcontroller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  uploadDocument() {
    return (
      <div data-test-id="fileTest" style={{marginTop:"50px"}}>
      <LabelText >
      Upload Product Photo
            </LabelText>
        <Box
          border="2px dashed #53503F"
          p={2}
          onDrop={this.handleDrop}
          textAlign="center"
          onDragOver={this.handleDragOver}
          sx={{
            display: 'flex',
            margin: 'auto',
            borderRadius:"4px",
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: 'rgba(83, 80, 63, 0.1)'
          }}
          data-testid="dropTestId"
          data-name="dropTest"
        >
          <Box sx={{ width: '100%' }}>
            <Typography
              component="label"
              htmlFor="file-upload" 
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 'auto', 
                fontWeight: 400,
                cursor: 'pointer',
                gap:"8px"
              }}
            >
              {/* <img src={""} alt="add icon" style={{color:"webStyle.uploadIconStyle"}} /> */}
              <FileUploadOutlinedIcon style={{fontSize:"30px"}}/>
              <span 
              style={{fontSize: '14px', fontWeight: 400, color: 'rgba(83, 80, 63, 1)'}} 
              className="montserratFont" >Drag & Drop here</span>
              <div style={{display:"flex",gap:"15px",alignItems:"center"}}>
                <hr  style={{
                    backgroundColor: "rgba(83, 80, 63, 1)",
                    height: "2px",
                    width: "77px",
                    border:"none"
                  }}/>
                OR
                <hr style={{
                    backgroundColor: "rgba(83, 80, 63, 1)",
                    height: "2px",
                    width: "77px",
                    border:"none",
                  }} />
              </div>
              <span
              style={{fontSize:"14px",gap:"7px",background:"rgba(83, 80, 63, 1)",fontFamily: "'Montserrat', sans-serif",color:"rgba(255, 255, 255, 1)",justifyContent:"center",fontWeight:600,borderRadius:"8px",width:"143px",height:"37px",display:"flex",alignItems:"center" }}
            >
              Browse files <ArrowForwardIosOutlinedIcon style={{fontSize:"14px"}}/>
            </span>
            <span style={{fontSize:"12px",fontWeight:400,fontFamily: "'Montserrat', sans-serif",}}>
            Only supports .jpg, .png format 
            </span>
                    <span style={{fontSize:"12px",fontWeight:400,marginTop:"-5px",fontFamily: "'Montserrat', sans-serif",}}>you can upload multiple files</span>
              <input
                id="file-upload"
                type="file"
                hidden
                multiple
                onChange={this.handleFileChange}
                accept=".jpg, .png, .pdf"
                name="imageFile"
                data-testid="imageTestId"
              />
            </Typography>
            <Typography className="montserratFont" sx={{color: 'rgba(83, 80, 63, 1)'}}>{configJSON.dragAndDrop}</Typography>
          </Box>
        </Box>
        <Typography className="montserratFont" style={{color:"webStyle.uploadNote"}}>{configJSON.note}</Typography>
        {this.state.errorMessage && (
          <Typography color="error" mt={2} sx={{margin: 'auto' }} className="montserratLightError">
            {this.state.errorMessage}
          </Typography>
        )}

        {this.state.previews.length > 0 && (
          <>
            <Box data-name="previewTestId" display="flex" flexWrap="wrap">
              {this.state.previews.map((preview: any, index: any) => {
                const fileType = this.state.selectedFiles[index].type;
                return (
                  <Box key={index} p={1}>
                    {fileType === 'application/pdf' ? (
                      <>
                      <Box sx={webStyle.previewDoc}>
                        <embed style={webStyle.embed} src={preview} width="200px" height="200px" />
                        <Box sx={webStyle.closeIcon}>
                          <CloseIcon style={{fontSize:"12px"}} onClick={() => this.handleRemoveFile(index)}/>
                        </Box>
                      </Box>
                      </>
                    ) : (
                      <>
                      <Box sx={webStyle.previewDoc}>
                        <img style={webStyle.imageUploaded} src={preview} alt="preview" width="200px" height="200px" />
                        <Box sx={webStyle.closeIcon}>
                        <CloseIcon style={{fontSize:"12px"}} onClick={() => this.handleRemoveFile(index)}/>
                        </Box>
                      </Box>
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </div>
    )
  }

  renderInputField(
    label: string, 
    placeholder: string, 
    value: string | undefined, 
    name: string, 
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    testId: string,
    multilineInputs?:any
  ) {
    return (
      <>
        <InputLabel className="montserratMediumGoogle">{label}</InputLabel>
        <TextField
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none', 
              },
              '&.Mui-focused fieldset': {
                border: 'none', 
              },
            },
            '& .MuiInputBase-input::placeholder': {
              fontFamily: "'Montserrat', sans-serif",
              color: '#53503F'
            },
            '& .MuiInputBase-input': {
              fontFamily: "'Montserrat', sans-serif",
              color: '#53503F'
            },
          }}
          fullWidth
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={webStyle.inputField}
          data-test-id={testId}
          multiline={multilineInputs}
          rows={multilineInputs?.rows}
          maxRows={multilineInputs?.maxRows}
        />
      </>
    );
  }
  confirmationModal=()=>{
    return (
        <Box  data-test-id="confirmationModal">
            <Modal
                open={this.state.openConfirmationModal}
                onClose={this.closeConfirmationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-test-id='confirmModal'
                sx={{              
                  maxWidth: '100%',
                  backdropFilter: "blur(6px)",
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
            >
                <RemoveCartModalBox >
                    <Box data-test-id='crossIconConfirmModal' className="crossIconBox" onClick={this.closeConfirmationModal}>
                       <CloseIcon/>
                    </Box>
                    <Typography style={webStyle.confirmation}>Confirmation</Typography>
                       <Typography className="areYouSureText">Are you sure you want to request
                       for this product?</Typography>
                    <CancelRemoveButtonBox>
                    <CancelButton data-test-id='NotNowBtn' onClick={this.closeConfirmationModal} variant="contained" >Not now</CancelButton>
                    <RemoveButton data-test-id='yesBtn' onClick={this.handleConfirmRequest} variant="contained" >Yes</RemoveButton>
                    </CancelRemoveButtonBox>
                </RemoveCartModalBox>
            </Modal>
        </Box>
    )
}
successModal=()=>{
  return (
      <Box >
          <Modal
              open={this.state.successModal}
              onClose={this.closeSuccessmodal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              data-test-id='succesModal'
              sx={{              
                maxWidth: '100%',
                backdropFilter: "blur(6px)",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
            }}
          >
              <RemoveCartModalBox >
                  <Box data-test-id='closeIconSuccess' className="crossIconBox" onClick={this.closeSuccessmodal}>
                     <CloseIcon/>
                  </Box>
                  <img src={makAWishImg} width={50} height={50} alt="wishIcon" />
                  <Typography style={webStyle.confirmation}>Success</Typography>
                     <Typography className="areYouSureText">Your wish request has been
                     submitted successfully</Typography>
                  <CancelRemoveButtonBox>
                  <RemoveButton style={{width:"360px"}} data-test-id='seeAllBtn' onClick={this.closeSuccessmodal} variant="contained" >See all requests</RemoveButton>
                  </CancelRemoveButtonBox>
              </RemoveCartModalBox>
          </Modal>
      </Box>
  )
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <>
        {/* Main Modal */}
        {this.props.openModal && !this.state.openConfirmationModal && (
          <Modal
            open={true}
            onClose={this.props.handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              maxWidth: '100%',
              backdropFilter: "blur(6px)",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            }}
            data-test-id="modalTest"
          >
            <Box sx={webStyle.mainContainer}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CloseIcon
                  onClick={this.props.handleCloseModal}
                  style={{
                    color: "#53503F",
                    height: "24px",
                    width: "24px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div
                className="wishimgdiv"
                style={{
                  display: "flex",
                  gap: "15px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img height={75} width={81} src={makAWishImg} alt="" />
                <span style={{ fontWeight: 600, fontSize: "24px" }}>
                  Make a Wish
                </span>
              </div>
              <ScrollableContent style={{ overflowX: "auto" }}>
                {this.uploadDocument()}
    
                <Grid container spacing={2} style={{ marginBottom: "10px", marginTop: "27px" }}>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                    <Box data-test-id="selectId" className='singleInputParentBox'>
            <LabelText >
                {"Category*"}
            </LabelText>
            <ReactSelect
                options={this.state.categoryList}
                placeholder={""}
                data-testid="countryCode"
                value={this.state.selectedCategory}
                onChange={(event) => this.handleChangeSelect(event, "selectedCategory")}
                data-test-id={"categoryTest"}
                classNamePrefix={'custom-select'}
                styles={{...selectStyles,menuList: (provided:any) => ({
                  ...provided,
                  scrollbarWidth: 'thin', 
                  scrollbarColor: '#888 #f1f1f1',
              
                  '&::-webkit-scrollbar': {
                    width: '8px',
                    borderRadius:"18px"
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius:"18px"
              
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '14px', 
              
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: '#555', 
                    borderRadius:"18px"
              
                  },
                }),}}
            />
        </Box>
                  </Grid>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                  <Box data-test-id="selectId1" className='singleInputParentBox'>
            <LabelText >
                {"Year of Production"}
            </LabelText>
            <ReactSelect
                options={this.state.YOPList}
                placeholder={""}
                data-test-id="productionTest"
                value={this.state.selectedYop}
                onChange={(event) => this.handleChangeSelect(event, "selectedYop")}
                classNamePrefix={'custom-select'}
                styles={selectStyles}
            />
        </Box>
                  </Grid>
                </Grid>
    
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                  <Box data-test-id="selectId1" className='singleInputParentBox'>
            <LabelText >
                {"Brand*"}
            </LabelText>
            <ReactSelect
                options={this.state.brandsList}
                placeholder={""}
                data-test-id="brandTest"
                value={this.state.selectedBrand}
                onChange={(event) => this.handleChangeSelect(event, "selectedBrand")}
                classNamePrefix={'custom-select'}
                styles={selectStyles}
            />
        </Box>
                  </Grid>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                    {this.renderInputField(
                      "Product Name*",
                      "product name",
                      this.state.productName,
                      'Product Name',
                      this.handleProductNameChange,
                      'productNameTest'
                    )}
                  </Grid>
                </Grid>
    
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {this.renderInputField(
                    "Description*",
                    "write here..",
                    this.state.productDesc,
                    'description',
                    this.handleProductDescChange,
                    'productNameTest1',
                   {rows:5,maxRows:8}
                  )}
                </Grid>
                <Grid style={{display:"flex",justifyContent:"space-between"}} item lg={12} md={12} sm={12} xs={12}>
                  <span></span>
                  <span style={{fontSize:"12px",fontWeight:600}}>{this.countWords(this.state.productDesc)}/20</span>
                </Grid>
    
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {this.renderInputField(
                    "Model Number of Product",
                    "Providing a model number will help us accurately find your request..",
                    this.state.modalNumber,
                    'modal number',
                    this.handleProductModalChange,
                    'productNameTest2'
                  )}
                </Grid>
                {this.state.productAndNameErr && (
          <Typography color="error" mt={2} sx={{margin: 'auto' }} className="montserratLightError">
            {this.state.productAndNameErr}
          </Typography>
        )}
    
                <div
                  className="button"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FooterButton
                  data-test-id="btnTest"
                    style={{ marginTop: "35px", marginBottom: "15px" }}
                    onClick={this.handleMakeAWishClick}
                  >
                    Make a Wish
                  </FooterButton>
                </div>
              </ScrollableContent>
            </Box>
          </Modal>
        )}
    
        {/* Confirmation Modal */}
        {this.state.openConfirmationModal && this.confirmationModal()}
    
        {/* Success Modal */}
        {!this.props.openModal && !this.state.openConfirmationModal && this.state.successModal && this.successModal()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const selectStyles={
    menu: (provided:any) => ({
        ...provided,
        border:'1px solid #53503F',
        boxShadow:'0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
        zIndex:10000,
        borderRadius:'4px',
        marginTop:'3.93px',
        overflow:'hidden',
        width:"50%",
        position:"absolute",
        right:0
       
      }),
      option: (provided:any,state:any) => ({
        ...provided,
          cursor: 'pointer', 
          gap:'8px',
          boxSizing: 'border-box',
          backgroundColor: state?.isSelected ? '#53503F' : 'unset',
          paddingRight: '16px',
          paddingLeft:'16px',
          paddingBottom:'12px',
          paddingTop:'12px',
          fontWeight: state?.isSelected ? 600 : 400,
          color:state?.isSelected?'#FFFFFF':'#3C3E49',
          fontFamily: "'Montserrat', sans-serif",
          fontSize: '12px',
          "&:hover": {
              backgroundColor: state?.isSelected ? '#53503F' : '#E0E0E0',
          }
                        
      }),
    
  }
const webStyle ={
    mainContainer:{
      boxSizing: "border-box",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "#EFEEE9",
      boxShadow: 24,
      p: 4,
      borderRadius: "4px",
      maxWidth: "45vw",
      width: "45vw",
      maxHeght:"93%",
      // height:"93%",
      // overflowX:"auto",
      outline: "none",
      padding:"20px 24px",
      fontFamily: "'Montserrat', sans-serif",
      color:"rgba(83, 80, 63, 1)"
    },
    inputField: {
      borderRadius: '4px',
      marginTop: '5px',
      backgroundColor: '#fff',
      marginBottom: '10px',
      border: 'none',
    },
    previewDoc: {
      border: '1px solid rgba(83, 80, 63, 1)',
      position: "relative",
      padding: '15px',
      backgroundColor: '#fff',
      borderRadius:"4px"
    },
    embed: {
      width: '50px',
      height: '50px'
    },
    imageUploaded: {
      height: '50px',
      width: '50px',
    },
    closeIcon: {
      position: 'absolute',
      right: '0px',
      top: '0px',
      cursor:"pointer"
    },
    confirmation: {
      fontSize: '22px',
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 600,
      color: '#53503F',
  },
  };
// Customizable Area End
