import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  getCategoryData:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  searchQuery:string;
  categoryData:any;
  categoryId:any;
  searchHistoryList:any;
  showSearchList:boolean,
  isLoggedIn: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  searchDebounceTimer:any;
  searchAPIId:any;
  searchHistoryApiId:any;
  deleteSearchAPIId:any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      searchQuery:"",
      categoryData:[],
      categoryId:"",
      searchHistoryList:["watch","bags"],
      showSearchList:false,
      isLoggedIn: false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
      const token = await getStorageData("loginToken");
      this.setState({ token, isLoggedIn: !!token }, () => {
        if (this.state.isLoggedIn) {
          this.getSearchHistory(); 
        }
      });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
   if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.searchAPIId:
            this.setState(
              {
                categoryData: responseJson.data,
                categoryId: responseJson?.data?.[0]?.attributes?.category_id,
              },
              () => {
                this.props.getCategoryData(this.state.categoryData, this.state.categoryId);
               this.handleGetSearchHistory(responseJson)
              }
            );
            break;
      
          case this.searchHistoryApiId:
            this.setState({ searchHistoryList: responseJson?.data });
            break;
      
          case this.deleteSearchAPIId:
            if (responseJson.message) {
              this.getSearchHistory();
            }
            break;
      
          default:
            break;
        }
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    },
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    },
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };
  handleGetSearchHistory=(responseJson:any)=>{
    if (responseJson.data.length > 0) {
      this.getSearchHistory();
    }
  }
  deleteSearch=async(searchId:any)=>{
    const token=await getStorageData("loginToken")
      this.deleteSearchAPIId=this.apiCall(token||"",{
        method:"DELETE",
        endPoint:`/bx_block_advanced_search/search/destroy_search?id=${searchId}`
      })
  }
  handleSearchClick=(searchTerm:string) => this.setState({ searchQuery: searchTerm,showSearchList:false },this.searchApiCall)
  getSearchHistory=async()=>{
    const token=await getStorageData("loginToken")
      this.searchHistoryApiId=this.apiCall(token,{
        method:"GET",
        endPoint:`/bx_block_advanced_search/search/history`
      })
  }
  handleSearchDebounce = () =>{
    this.searchDebounceTimer = setTimeout(async() => {
      this.searchApiCall()
      
     }, 500); // time in milliseconds
    }
    searchApiCall=async()=>{
      const token=await getStorageData("loginToken")
      if(this.state.searchQuery){
        this.searchAPIId=this.apiCall(token,{
          method:"GET",
          endPoint:`/bx_block_advanced_search/search/catalogues?query=${this.state.searchQuery}`
        })
      }
    }
    apiCall = (token: any,payload:any) => {
      let {method,endPoint}=payload
      const header = {
        "Content-Type": configJSON.productApiContentType,
        token: token||"",
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       method
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
     return requestMessage.messageId;
  
    };
    handleFocus = () => {
      this.setState({ showSearchList: true });
    };
  
    handleBlur = () => {
      this.setState({ showSearchList: false });
    };
  
  handleSearchQuery =(e:any)=>{
    if (this.searchDebounceTimer) { 
    clearTimeout(this.searchDebounceTimer);
     }
     this.setState({searchQuery:e.target.value},this.handleSearchDebounce)
    }

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText,
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText,
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText,
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
