import React from "react";

// Customizable Area Start
import {
  createTheme,
} from "@mui/material/styles";

import {Box, Grid, InputLabel, TextField, Button, Checkbox,ToggleButton, ToggleButtonGroup, InputAdornment, IconButton} from '@mui/material';
import { eyeOpenIcon, eyeCloseIcon,checkBox,checkedBox,downArrow,calenderIcon } from "./assets";
import EmailAccountRegistrationController, {
    Props,
    configJSON
  } from "./EmailAccountRegistrationController";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MONTSERRAT_THIN } from '../../../components/src/fontConstants.web';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class EmailAccountRegistrationWeb extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInputField(
    placeholder: string, 
    label: string, 
    value: string | undefined, 
    name: string, 
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    error: string,
    dataTestId: string,
  ) {
    return (
      <Grid item lg={6} md={4} sm={4} xs={12}>
        <InputLabel className="montserratMediumGoogle">{label}</InputLabel>
        <TextField
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border: '1px solid #53503F',
              },
            },
            "& fieldset": { border: error ? '1px solid #DC2626':'none' },
            '& .MuiInputBase-input::placeholder': {
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              color: '#53503F'
            },
            '& .MuiInputBase-input': {
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              color: '#53503F'
            },
          }}
          fullWidth
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          data-testid={dataTestId}
          style={styles.inputField}
        />
        {error ? (
          <p style={styles.error} id='emailError' className='montserratLightError'>
            {error}
          </p>
          ) : null
        }
        {name === 'email' && this.state.emailBackendError ? (
          <p style={styles.error} id='emailError' className='montserratLightError'>
            Email {this.state.emailBackendError}
          </p>
          ) : null
        }
      </Grid>
    );
  }
  
  renderPasswordField(
    name: string,
    placeholder: string, 
    label: string, 
    value: string, 
    dataTestId: string, 
    eyeState: boolean, 
    handleEyeClick: () => void, 
    eyeIconTestId: string,
    error: string,
    handleChange: any,
  ) {
    return (
      <Grid item lg={6} md={4} sm={4} xs={12}>
        <InputLabel className="montserratMediumGoogle">{label}</InputLabel>
        <Box sx={styles.passwordField}>
          <TextField
            sx={{
              "& fieldset": { border: error ? '1px solid #DC2626':'none' },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  border: '1px solid #53503F',
                },
              },
              '& .MuiInputBase-input::placeholder': {
                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                color: '#53503F'
              },
              '& .MuiInputBase-input': {
                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                color: '#53503F'
              },
              }}
            name={name}
            fullWidth
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            type={eyeState ? 'text' : 'password'}
            style={styles.inputField}
            data-testid={dataTestId}
          />
          <Box
            data-testid={eyeIconTestId}
            sx={styles.eyeIconMainWithOutError}
            onClick={handleEyeClick}
          >
            <img src={eyeState ? eyeOpenIcon : eyeCloseIcon} alt="eyeIcon" />
          </Box>
        </Box>
        {error ? (
            <p style={styles.error} id='emailError' className='montserratLightError'>
              {error}
            </p>
            ) : null
          }
      </Grid>
    );
  }
  
  
  // Customizable Area End

  render() {
     // Customizable Area Start
      // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <Box sx={styles.root}>
        <Box style={styles.formMain}>
          <form>
            <Grid container spacing={2}>
            {this.renderInputField(
              configJSON.firstNamePlaceholder,
              configJSON.labelFirstName,
              this.state.firstUserName,
              'firstName',
              this.handleFirstUserName,
              this.state.firstUserNameError,
              'firstNameTestId',
            )}
            {this.renderInputField(
              configJSON.lastNamePlaceholder,
              configJSON.lastName,
              this.state.lastUserName,
              'lastName',
              this.handleLastUserName,
              this.state.lastUserNameError,
              'lastNameTestId',
            )}
            {this.renderInputField(
              configJSON.emailPlaceholder,         
              configJSON.placeHolderEmail,         
              this.state.emailAddress,                    
              'email',                             
              this.handleEmailChange,              
              this.state.emailAddressError,                  
              'emailTestId',
            )}
            <Grid item lg={6} md={4} sm={4} xs={12}>
              <InputLabel className="montserratMediumGoogle">{configJSON.locationText}</InputLabel>
              <FormControl fullWidth sx={styles.selectField}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.location}
                onChange={this.handleLocationChange}
                open={this.state.openDropdown} 
                onClose={() => this.setState({ openDropdown: false })}
                onOpen={() => this.setState({ openDropdown: true })}
                data-testid="locationTestId"
                displayEmpty
                sx={{
                  ...styles.locationDropdown,
                  '& .MuiSelect-select': {
                    color: '#53503F',
                    fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`
                  },
                }}
                name="location"
                MenuProps={{
                  PaperProps: {
                    style: {
                      border: '1px solid #53503F',
                      minWidth: '200px',
                      width: '100px',
                      height: '180px',
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom', 
                    horizontal: 'right',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                }}
                IconComponent={() => 
                <img 
                  src={downArrow} 
                  alt="arrow" 
                  style={{padding: '10px'}}
                  data-testid="dropDownClickTest"
                  onClick={this.handleIconClick}
                />}
              >
                <MenuItem value="" id="dataaaa"  
                sx={{
                fontFamily: 'Montserrat, sans-serif',
                '&.Mui-selected:hover': {
                  backgroundColor: '#5b5b5b',
                },
                '&.Mui-selected': {
                  backgroundColor: '#80806e',  
                  color: '#fff',
                },
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
                }}>
                  <em style={styles.selectPlaceholder}>Select</em>
                </MenuItem>
                {Array.isArray(this.state.locationData) && this.state.locationData.map((locate) => 
                  <MenuItem 
                    value={locate.attributes.id} 
                    sx={{
                    '&.Mui-selected': {
                      fontWeight: '400 !important',
                      backgroundColor: '#53503F', 
                      color: '#ffffff',
                      fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`
                    },
                    '&:hover': {
                      backgroundColor: '#53503F',
                      color: '#ffffff'
                    },
                  }}>{locate.attributes.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            {this.state.locationError ? (
              <p style={styles.error} id='emailError' className='montserratLightError'>
                {this.state.locationError}
              </p>
              ) : null
            }
            </Grid>
            {this.renderPasswordField(
              'password',
              configJSON.placeholderPass,            
              configJSON.labelPassword,            
              this.state.userPassword,                
              'passwordTestId',                   
              this.state.eyeIconPassword,         
              this.handlePasswordEye,             
              'eyeIconPasswordTestId',
              this.state.userPasswordError,                        
              this.handleUserPassword,
            )}
            {this.renderPasswordField(
              'confirmPassword',
              configJSON.placeholderConfirmPass,          
              configJSON.confirmPassword,          
              this.state.confirmUserPassword,         
              'confirmpasswordTestId',            
              this.state.eyeIconConfirm,          
              this.handleConfirmEye,             
              'eyeIconConfirmPassTestId',
              this.state.confirmUserPasswordError,       
              this.handleConfirmUserPassword,
            )}
            <Grid item lg={6} md={4} sm={4} xs={12}>
              <InputLabel className="montserratMediumGoogle">Date of Birth*</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div style={{ width: '100%', marginTop: '5px',marginBottom: '10px' }}>
                    <DatePicker
                      data-testid="dateOfBirthTestId"
                      name='dateOfBirth'
                      value={this.state.dateOfBirth}
                      onChange={(newValue) => this.handleDate(newValue ? new Date(newValue) : null)}
                      open={this.state.calendarOpen}
                      onClose={() => this.setState({ calendarOpen: false })} 
                      slots={{
                        textField: TextField 
                      }}
                      slotProps={{
                        textField: {
                          placeholder: this.state.dateOfBirth ? "" : "dd/mm/yyyy",
                          InputProps: {
                            endAdornment: ( 
                              <InputAdornment position="end"> 
                                <IconButton edge="end"  onClick={this.handleCalendarIconClick}> 
                                  <img src={calenderIcon} alt='data'/> 
                                </IconButton> 
                              </InputAdornment> 

                            ), 

 
                            sx: {
                              '&::placeholder': {
                                color: '#BABABA',
                                textDecoration: 'none'
                              },
                              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                              color: this.state.dateOfBirth ? '#53503F' : '#BABABA', 
                            },
                          },
                          sx: {
                            backgroundColor: '#fff',
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: this.state.dateOfBirthBackendError ? '1px solid #DC2626':'none'
                            },
                            '& .MuiInputBase-input': {
                              padding: '15px 14px',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              border: this.state.dateOfBirthBackendError ? '1px solid #DC2626':'none'
                            },
                            '& .Mui-error': {
                              border: this.state.dateOfBirthBackendError ? '1px solid #DC2626':'none'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: this.state.dateOfBirthBackendError ? '1px solid #DC2626':'none'
                            },
                            borderRadius: '4px',
                          },
                          fullWidth: true,
                        },
                        popper: {
                          sx: {
                            ".MuiPickersCalendarHeader-root": {
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: '0 16px',
                              color: 'blue', 
                            },
                            ".MuiPaper-root": { 
                              borderRadius: "10px",
                              backgroundColor: '#fff', 
                              color: '#53503F', 
                            },                            
                            ".MuiPickersCalendarHeader-labelContainer": {
                              position: 'absolute', 
                              left: '49%',
                              transform: 'translateX(-50%)',
                              color: '#53503F', 
                            },
                            ".MuiPickersArrowSwitcher-root": {
                              width: '100%', 
                              justifyContent: 'space-between',
                              display: 'flex',
                              color: 'pink', 
                            },
                            ".Mui-selected:hover": {
                              backgroundColor: '#53503F',
                            },
                            ".Mui-selected": {
                              backgroundColor: '#53503F', 
                              color: 'pink', 
                            },
                            ".MuiPickersDay-root": {
                              color: '#53503F',
                            },
                            ".MuiPickersDay-root:hover": {
                              backgroundColor: '#53503F',
                              color: 'black', 
                            },
                            ".MuiPickersDay-root.Mui-disabled": {
                              color: 'rgba(255, 255, 255, 0.3)', 
                            },
                            ".MuiPickersDay-root.Mui-selected": {
                              color: '#fff', 
                              backgroundColor: '#53503F', 
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </LocalizationProvider>
                {this.state.dateOfBirthBackendError ? (
                  <p style={styles.error} id='emailError' className='montserratLightError'>
                    Date of birth {this.state.dateOfBirthBackendError}
                  </p>
                  ) : null
                }
            </Grid>
            <Grid item lg={6} md={4} sm={4} xs={12}>
              <InputLabel className="montserratMediumGoogle">Gender*</InputLabel>
              <ToggleButtonGroup
                value={this.state.gender}
                onChange={this.handleGenderChange}
                fullWidth
                aria-label="gender selection"
                sx={styles.toggleMain}
                data-testid="genderTestId"
                exclusive
              >
                <ToggleButton value="Male" aria-label="male" sx={{...styles.toggleBtn, textTransform: 'none',fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`}}>Male</ToggleButton>
                <ToggleButton value="Female" aria-label="female" sx={{...styles.toggleBtn, textTransform: 'none',fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`}}>Female</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item lg={12} md={12} sm={4} xs={12}>
              <Box sx={styles.termCondition}>
                <Checkbox 
                sx={{
                  "&.Mui-checked": {
                    color: '#53503F'
                }}} 
                checkedIcon={<img src={checkedBox} alt='checkedBox'/>}
                icon={<img src={checkBox} alt='checkbox' />}
                value={this.state.termsCondition} 
                onChange={this.handleTermsCondition}
                name="termsCondition"
                data-testid="termsConditionTestId"
                />
                <p className="montserratFont">I agree with <span onClick={this.props.handleOpenTermsModal} style={{...styles.termBold,fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif` }}>Terms and Conditions</span> & <span onClick={this.props.handleOpenPrivacyModal} style={{...styles.termBold ,fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`}}>Privacy Policy</span></p>
              </Box>
            </Grid>
            </Grid> 
            <Box style={styles.createAccount}>
              <Button 
              disabled={this.state.firstUserName === "" || this.state.lastUserName === "" || this.state.emailAddress === "" || this.state.termsCondition === false || this.state.location === "" || this.state.gender === "" || this.state.dateOfBirth === "" || this.state.userPassword === "" || this.state.confirmUserPassword === ""} 
              data-testid='submitTestId' onClick={this.handleSubmit} variant="contained" 
              className="montserratLightGoogle"
              sx={{
                ...styles.btn, 
                '&.Mui-disabled': {
                  backgroundColor: '#53503F', 
                  color: '#EDE6DC', 
                  cursor: 'not-allowed',
                  opacity: 0.5,
                  fontSize: '18px'
                },
              }}>
                Create Account
              </Button>
            </Box>
            <span data-test-id="spanTest" className="spanTest"></span>
          
          </form>
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  selectPlaceholder: {
    color: '#B0B0B0',
    fontSize: 'inherit',
    display: 'block',
    fontStyle: 'normal',
  },
  formMain: {
    width: '100%'
  },
  inputField: {
    borderRadius: '4px',
    marginTop: '5px',
    backgroundColor: '#fff',
    marginBottom: '10px',
    border: 'none',
  },
  passwordField: {
    position: 'relative'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#EFEEE9', 
    padding: '16px',
    borderRadius: 8,
  },
  logo: {
    padding: '10px'
  },
  fieldLabel: {
    fontFamily: "Avenir",
    fontSize: '10px',
    lineHeight: '24px',
    fontWeight: 800,
    color: '#53503F',
    textAlign: "left",
  }, 
  remember: {
    display: 'flex',
  },
  eyeIconMainWithOutError: {
    position: 'absolute',
    right: '15px',
    bottom: '40%'
  },
  eyeIconWithError: {
    position: 'absolute',
    bottom: '56%',
    right: '15px',
  },
  rememberMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  createAccount: {
    margin: 'auto',
    width: '40%'
  },
  btn: {
    backgroundColor: '#53503F',
    color: '#fff',
    paddingBottom: '15px',
    marginTop: '10px',
    paddingTop: '15px',
    borderRadius: '8px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: '#403D2F',
      boxShadow: 'none',
    },
    '.MuiButtonBase-root-MuiButton-root': {
      padding: '100px'
    }
  },
  welcomeText: {
    fontFamily: "Avenir",
    fontWeight: 400,
    fontSize: '20px',
  },
  error: {
    margin: '0px',
    color: '#DC2626',
    fontSize: "12px",
    paddingBottom: '15px'
  },
  selectField: {
    marginTop: '5px',
    backgroundColor: '#f0f0f0', 
    marginBottom: '10px',
    '&:focus': {
        backgroundColor: '#f0f0f0',
    },
  },
  radioField: {
    marginTop: '5px',
    marginBottom: '10px',
    backgroundColor: '#f0f0f0', 
    '&:focus': {
        backgroundColor: '#f0f0f0',
    },
  },
  toggleMain: {
    gap: '15px',
    marginTop: '5px',
    marginBottom: '10px'
  },
  toggleBtn: {
    borderRadius: '3px !important',
    textDecoration: 'none',
    border: '2px solid #53503F !important', 
    color: '#53503F', 
    '&.Mui-selected': {
      backgroundColor: '#53503F', 
      color: '#fff',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#53503F',
    },
  },
  locationDropdown: {
      backgroundColor: 'white',  
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', 
      },
  },
  termCondition: {
    display: 'flex',
    color: '#53503F'
  },
  termBold: {
    fontWeight: 600,
    textDecoration: 'underline',
    cursor:"pointer",
  }
};
// Customizable Area End