import React from "react";

// Customizable Area Start
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography, Pagination, 
  PaginationItem, Menu, MenuItem, Chip, FormControl, InputLabel, Select, TextField, InputAdornment 
} from '@mui/material';
import ShopperHeader from "../../../components/src/ShopperHeader.web";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { menuIcon, downArrow, rightArrow, filterIcon, searchIcon } from "./assets";
import { MONTSERRAT_THIN } from '../../../components/src/fontConstants.web';
import {AppHeader} from '../../../components/src/AppHeader.web';
// Customizable Area End

import RequestManagementController, {
  Props,
  configJSON,
} from "./RequestManagementController";

export default class RequestManagement extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  searchBar() {
    return (
      <FormControl variant="outlined" fullWidth>
        <TextField
        data-testid="searchTestId"
        value={this.state.searchTerm}
        onChange={(event) => this.handleSearchChange(event.target.value)} 
        placeholder="Search Brands"
        variant="outlined"
        sx={webStyle.searchFieldStyle}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={searchIcon} alt="searchIcon"/>
            </InputAdornment>
          ),
          endAdornment: null,
        }}
        fullWidth
        />
      </FormControl>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { anchorEl, openMenuIndex, offerListData } = this.state;

    return (
      <>
      <AppHeader data-testid="appHeaderTestId" offerFilter={this.state.offerFilter} offerFilterClose={this.handleCloseOfferFilter} offerFilterApply={this.handleApplyOfferButton}/>
      <Box style={webStyle.mainRequestTable} data-testid="tableMainTestId">
        <Typography className="montserratFont" sx={webStyle.homeNavigate}>{configJSON.profile}
          <img src={rightArrow} alt='rightIcon'/>
          <span style={{fontWeight: 500}}>{configJSON.offers}</span>
        </Typography>
        <Box style={webStyle.searchSortMain}>
          <Box sx={webStyle.filterSearchItem}>
            <Button 
              variant="contained" 
              className="montserratFont"
              sx={webStyle.actionBtn}
              startIcon={<img src={filterIcon} alt="filterIcon" />}
              data-testid="openFilterTestId"
              onClick={this.handleOpenOfferFilter}
            >
              {configJSON.filter} 
            </Button>
            {this.searchBar()}
          </Box>
          <Box>
          <FormControl style={webStyle.formControl}>
            {this.state.sortOption ? '' : 
            <InputLabel
              className="montserratFont"
              htmlFor="sortOption"
              style={{
                position: 'absolute',
                top: '50%',
                left: '10%',
                transform: 'translateY(-50%)',
                fontSize: '14px', 
                color: 'rgba(83, 80, 63, 1)', 
                pointerEvents: 'none', 
              }}
            >
              Sort by
            </InputLabel>
            }
            <Select
              className="montserratFont"
              name="sortOption"
              value={this.state.sortOption}
              data-testid="sortOptionTestId"
              onChange={this.handleSortOption}
              renderValue={(selected) => `Sort by: ${selected}`}  
              sx={{
                ...webStyle.select, 
                backgroundColor: 'transparent',
                fontWeight: 400,
                border: '1px solid rgba(83, 80, 63, 1)',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none', 
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: webStyle.menuProps,
                },
              }}
              inputProps={{ className: "montserratFont" }}
            >
              <MenuItem 
              className="montserratFont"
              value="Price: High To Low" 
              data-testid="priceHightToLowTestId"
              style={webStyle.menuItem} 
              >
                Price: High to Low
              </MenuItem>
              <MenuItem 
              className="montserratFont"
              value="Price: Low To High" 
              data-testid="priceLowToHighTestId"
              style={webStyle.menuItem} 
              >
                Price: Low to High
              </MenuItem>
              <MenuItem 
              className="montserratFont"
              value="Older" 
              data-testid="priceHightToLowTestId"
              style={webStyle.menuItem} 
              >
                Older
              </MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Box>
        <Box style={{margin: '15px 15px'}}>
          <TableContainer>
            <Table data-testid="tableTestId">
              <TableHead data-testid="tableHead">
                <TableRow style={{ backgroundColor: '#ffffff' }}>
                  <TableCell className="montserratFont" sx={webStyle.tableHeading}>{configJSON.offerIdLabel}</TableCell>
                  <TableCell className="montserratFont" sx={webStyle.tableHeading}>{configJSON.productImageLabel}</TableCell>
                  <TableCell className="montserratFont" sx={webStyle.tableHeading}>{configJSON.dateLabel}</TableCell>
                  <TableCell className="montserratFont" sx={webStyle.tableHeading}>{configJSON.categoryLabel}</TableCell>
                  <TableCell className="montserratFont" sx={webStyle.tableHeading}>{configJSON.productNameLabel}</TableCell>
                  <TableCell className="montserratFont" sx={webStyle.tableHeading}>{configJSON.modalNumberLabel}</TableCell>
                  <TableCell className="montserratFont" sx={webStyle.tableHeading}>{configJSON.listedPriceLabel}</TableCell>
                  <TableCell className="montserratFont" sx={webStyle.tableHeading}>{configJSON.offerPriceLabel}</TableCell>
                  <TableCell className="montserratFont" sx={webStyle.tableHeading}>{configJSON.statusLabel}</TableCell>
                  <TableCell className="montserratFont" sx={webStyle.tableHeading}>{configJSON.actionLabel}</TableCell>
                  <TableCell className="montserratFont" sx={webStyle.tableHeading}></TableCell>
                </TableRow>
              </TableHead>
              
              <TableBody data-testid="tableBodyTestId">
                {offerListData.map((item: any, index: number) => 
                  <TableRow key={item.id} 
                  style={{ 
                    backgroundColor: index % 2 === 0 ? 'white' : 'rgba(239, 238, 233, 1)', 
                  }}
                  >
                    <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.offer_id} </TableCell>
                    <TableCell>
                      <img src={item.attributes.variant_info.attributes.images[0].url} alt="Product" 
                      style={{ width: '50px', height: '50px' }} />
                    </TableCell>
                    <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.created_at} </TableCell>
                    <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.category_name} </TableCell>
                    <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.product_name} </TableCell>
                    <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.model_number} </TableCell>
                    <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.order_price} </TableCell>
                    <TableCell className="montserratFont" sx={webStyle.tableData}> {item.attributes.offer_price} </TableCell>
                    <TableCell>
                      <Chip label={item.attributes.status}  sx={webStyle.chipStyle} />
                    </TableCell>
                    <TableCell>
                    {item.attributes.status !== "pending" ?
                      <Button 
                        variant="contained" 
                        className="montserratFont"
                        sx={webStyle.actionBtn}
                        endIcon={<ChevronRightIcon />}
                      >
                        Buy now
                      </Button> : null }
                    </TableCell>
                    <TableCell>
                    <img
                      data-testid="menuTestId"
                      src={menuIcon}
                      alt="More options"
                      onClick={(e) => this.handleMenuClick(e, index)}
                      style={{ cursor: 'pointer', width: 24, height: 24 }} 
                    />
                      <Menu
                        anchorEl={anchorEl}
                        open={openMenuIndex === index}
                        onClose={this.handleCloseMenu}
                      >
                        <MenuItem 
                        className="montserratFont" 
                        data-testid="withdrawOfferTestId"
                        sx={webStyle.tableData} 
                        onClick={() => {
                          this.handleCloseMenu()
                          this.handleWithdrawOffer(item.id)
                        }}
                        >
                          Withdraw Offer
                        </MenuItem>
                        <MenuItem 
                        className="montserratFont" 
                        data-testid="navigateToProductTestId"
                        sx={webStyle.tableData}
                        onClick={() => this.handleNavgiateToProductDescription(item.id)}
                        >
                          Details
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <Box style={webStyle.paginationMain}>
              <Box style={webStyle.dataCountMain}>
                <Box className="montserratFont" style={webStyle.showCount}>Show count</Box>
                <Box style={webStyle.count}>
                  <span style={webStyle.countNumber}>10</span>
                  <img src={downArrow} alt="downArrow"/>
                </Box>
              </Box>
              <Pagination
              data-testid="paginationTestId"
              count={this.state.totalPages}
              sx={webStyle.pagination}
              onChange={this.handlePageChange}
              renderItem={(item) => {
                if (item.type === 'previous') {
                  return (
                    <PaginationItem
                      {...item}
                      components={{
                        previous: () => (
                            <span>Prev</span>
                          ),
                      }}
                    />
                  );
                }
                if (item.type === 'next') {
                  return (
                    <PaginationItem
                      {...item}
                      components={{
                        next: () => <span>Next</span>
                      }}
                    />
                  );
                }
                return <PaginationItem {...item} />;
              }}
              />
            </Box>            
          </TableContainer>
        </Box>
      </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  searchFieldStyle: {
    fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
    backgroundColor: '#F9F9F9',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
      border: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
  homeNavigate: {
    padding: '10px 15px',
    width: '30%', 
    fontSize: '18px', 
    display: 'flex'
  },
  mainRequestTable: {
    backgroundColor: 'rgba(237, 230, 220, 1)'
  },
  tableHeading: {
    color: 'rgba(83, 80, 63, 1)',
    fontWeight: 600, 
    fontSize: '14px'
  },
  tableData: {
    fontSize: "16px",
    fontWeight: 400, 
    marginBottom: "8px", 
    color: "rgba(83, 80, 63, 1)"
  },
  chipStyle: {
    backgroundColor: 'rgba(239, 238, 233, 1)', 
    color: 'rgba(83, 80, 63, 1)', 
    border: '1px solid rgba(83, 80, 63, 1)'
  },
  actionBtn: {
    fontSize: '12px', 
    color: 'rgba(237, 230, 220, 1)', 
    backgroundColor: 'rgba(83, 80, 63, 1)', 
    padding: '4px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(239, 238, 233, 1)',
    color: 'rgba(83, 80, 63, 1)'
    },
    '&:focus': {
      outline: 'none', 
      boxShadow: 'none'
    },
    '&:active': {
      backgroundColor: 'inherit', 
    },
    '&.MuiButtonBase-root': {
      outline: 'none',
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
  paginationMain: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 15px',
    backgroundColor: '#ffffff'
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      backgroundColor: 'rgba(239, 238, 233, 1)'
    },
    '& .MuiPaginationItem-root.Mui-selected': {
      backgroundColor: 'rgba(83, 80, 63, 1)',
      color: 'rgba(255, 255, 255, 1)'
    },
    '& .MuiPaginationItem-previous, & .MuiPaginationItem-next': {
      borderRadius: 'none',
      backgroundColor: 'red',
      color: '#1976d2'
    },
  },
  showCount: {
    fontSize: '14px',
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)',
    padding: '3px 0px'
  },
  dataCountMain: {
    display: 'flex'
  },
  countNumber: {
    padding: '4px 0px'
  },
  count: {
    border: '1px solid rgba(239, 238, 233, 1)',
    display: 'flex',
    padding: '0px 15px',
    margin: '0px 15px'
  },
  menuProps: {
    borderRadius: 8,
    backgroundColor: '#f2f2e6',
  },
  menuItem: {
    fontSize: '14px',
    color: '#333',
    padding: '10px 20px',
    fontFamily: 'Montserrat, sans-serif',
    '&.Mui-selected:hover': {
      color: '#fff',
      backgroundColor: '#5b5b5b',
    },
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: '#80806e',  
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#e0e0e0',
    }
  },
  formControl: {
    minWidth: 160,
    borderRadius: 8,
    color: '#8c8c8c',
    fontSize: '14px',
  },
  select: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#333',
    backgroundColor: '#f2f2e6',
  },
  searchSortMain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 15px'
  },
  filterSearchItem: {
    display: 'flex'
  }
};
// Customizable Area End
